import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    tags: [],
  },


  mutations: {
    setTags(state, payload) {
      state.tags = payload
    },
    addTag(state, payload) {
      state.tags.push(payload)
    }
  },


  actions: {
    async initState(ctx, doUpdate){
      if (ctx.state.tags.length != 0 && !doUpdate ) return;

      var tempTags = []

      await firebase.firestore().collection("Tags").get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => { tempTags.push(doc) });
      });

      tempTags = tempTags.sort( function(a, b) { return a.data().sort - b.data().sort } )

      ctx.commit('setTags', tempTags)
      console.log('tag init.')
    }
  }
}
