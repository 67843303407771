<template>
  <div class="a-form">
    <!-- text -->
    <qa-md-editor 
      id="a-text" 
      :value="text" 
      @input="textInputed" 
      @paste="pasted"
      placeholder="# 回答してみよう！" />
    <small>※画像はクリップボードから貼り付けできます。</small>
    <!-- /text -->

    <!-- imgs -->
    <div style="display: flex;">
      <div v-for="f of files" :key="f.path">
        <qa-img 
          :href="f.href" 
          :dataLightbox="f.dataLightbox"
          :showButton="true"
          @buttonClick="deleteFile(f.path)">
        </qa-img>
      </div>
    </div>
    <!-- imgs -->

    <qa-button @click="registerAnswer">投稿</qa-button>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import QaMDEditor from '@/components/molecules/QaEditor-MD.vue'
  import QaButton from '@/components/atoms/QaButton'
  import QaImgThumbnail from '@/components/atoms/QaImgThumbnail.vue'
  import Swal from 'sweetalert2'
  export default {
    components: {
      'qa-button': QaButton,
      'qa-md-editor': QaMDEditor,
      'qa-img': QaImgThumbnail,
    },
    data() {
      return {
        text: '',
        htmlText: '',
        files: []
      }
    },
    computed: {
      ...mapState('auth-user',[
        'uid',
      ]),
      ...mapState('store-user',[
        'displayName',
        'photoUrl'
      ]),
      ...mapState('store-question',[
        'selectedQuestionId'
      ]),
    },
    methods: {
      ...mapActions('store-answer', {
        storeRegisterAnswer: 'registerAnswer'
      }),
      ...mapActions('storage', {
        storageUploadFile: 'uploadQuestionFile',
        storageDeleteFile: 'deleteFile',
        storageGetDownloadUrl: 'getDownloadUrl',
      }),
      textInputed(e, htmlValue) { 
        this.text = e.target.value; 
        this.htmlText = htmlValue 
      },
      async deleteFile(path) {
        this.files = this.files.filter( i => i.path != path )
        await this.storageDeleteFile(path)
      },
      async pasted(e) {
        var IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i;
        var items = e.clipboardData.items;
        for (var i = 0; i < items.length; i++) {
          if (IMAGE_MIME_REGEX.test(items[i].type)) {
            var tempf = items[i].getAsFile()

            // upload
            console.log(items[i].getAsFile())
            var path = await this.storageUploadFile(tempf)
            var url = await this.storageGetDownloadUrl(path)

            // file reader 
            var reader = new FileReader()
            reader.onload = () => {
              var f = { 
                path: path,
                href: url,
                dataLightbox: path,
              }
              this.files.push(f)
            };

            // read
            reader.readAsDataURL(tempf);
            return;
          }
        }
        e.PreventDefault()
      },
      async registerAnswer() {
        var a = {}
        var filePath = []
        for ( var f of this.files ){ 
          filePath.push(f.path) 
        }

        if ( filePath.length === 0 && this.text.trim() === '' ) {
          Swal.fire({
            icon: 'error',
            title: '回答内容確認中エラー',
            text: '本文を入力するか、添付ファイルを貼りましょう。',
          })
          return
        }

        a.filePath = filePath
        a.questionId = this.selectedQuestionId
        a.uid = this.uid
        a.displayName = this.displayName
        a.text = this.text
        a.htmlText = this.htmlText
        await this.storeRegisterAnswer(a)
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: '投稿しました！',
          showConfirmButton: false,
          timer: 1000
        })

        this.text = ''
        this.htmlText = '';
        this.files = []
        //this.$router.push({ name: 'answers', 
        //                    params: {questionId: this.$route.params.questionId},  
        //                    query: this.$route.query})
      },
    }
  }
</script>
<style lang="scss" scoped>
  .a-form {
    @include block(
      $hover-background-color: transparent
    );

    &>button {
      margin-top: 10px;
      width: 100%;
    }
  }
</style>
