<template>
  <div :class="cardCss">
    <!-- upper-content -->
    <div class="card-question-upper" @click="$emit('click', questionId)">
      <!-- header -->
      <div class="card-question-upper header">
        <!-- title -->
        <h3> {{ title }} </h3>
        <!-- /title -->

        <!-- bell -->
        <div @click="$emit('bellClick', questionId, title, $event)">
          <i v-if="bellSetted" class="fa-solid fa-bell fa-l"></i> 
          <i v-else class="fa-regular fa-bell fa-l"></i> 
        </div> 
        <!--/bell -->
      </div>
      <!-- /header -->


      <!-- text -->
      <div v-if="showFullText" class="card-question-upper body-full">
        <div v-html="taggedText" class="markdown-body"></div>
      </div>
      <div v-else class="card-question-upper body">
        <p> {{ _tagRemovedText }} </p>
      </div>
      <!-- /text -->

      <!-- thumbnails -->
      <div v-if="showFullText" style="display: flex;">
        <div v-for="f of files" :key="f.path">
          <qa-img 
            :href="f.href" 
            :dataLightbox="f.dataLightbox"
            :showButton="false"
            @buttonClick="storageDeleteFile(f.path)">
          </qa-img>
        </div>
      </div>
      <!-- /thumbnails -->
    </div>
    <!-- /upper-content -->
    <!-- lower-content -->
    <div class="card-question-lower" @click="$emit('click', questionId)">
      <!-- user icon,name -->
      <div style="flex-wrap: wrap;">
        <qa-user-icon :photoUrl='userPhotoFileUrl' :routerTo="userRouterTo" />
        <span style="margin: 0 10px; font-size: 0.9rem;"> {{ userName }} </span>
      </div>
      <!-- /user icon,name -->

      <!-- posted timestamp -->
      <div>
        <i class="fa-regular fa-clock card-question-lower date"></i> 
        <p class="card-question-lower date"> {{ displayDate }} </p>
      </div>
      <!-- /posted timestamp -->

      <!-- point -->
      <div> <span>{{ point }} Points</span> </div>
      <!-- /point -->

      <!-- status -->
      <div 
        @click="$emit('buttonClick', questionId)" 
        :disabled="buttonDisabled" 
        :class="statusCss" >
        {{ buttonText }}
      </div>
      <!-- /status -->
    </div>
    <!-- /lower-content -->
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import QaUserIcon from '@/components/atoms/QaIcon-User'
  import QaImgThumbnail from '@/components/atoms/QaImgThumbnail.vue'

  export default {
    props: {
      questionId: String,
      title: String,
      postedDate: Object,
      buttonText: String,
      buttonDisabled: Boolean,
      taggedText: String,
      userId: String,
      userName: String,
      userRouterTo: Object,
      point: Number,
      isResolved: Boolean,
      isSelected: Boolean,
      showFullText: Boolean,
      isColoredWhenHovered: Boolean,
      isStatusColoredWhenHovered: Boolean,
      filePath: Array,
      bellSetted: Boolean,
    },
    async mounted() {
      if ( this.filePath != undefined ) {
        for ( var i = 0; i < this.filePath.length; i ++) {
           var url = await this.storageGetDownloadUrl(this.filePath[i])
           var f = { 
             path: this.filePath[i],
             href: url,
             dataLightbox: this.filePath[i],
           }
           this.files.push(f)
        }
      }
      await this._setUserPhotoFileUrl()
    },
    components: {
      'qa-img': QaImgThumbnail,
      'qa-user-icon': QaUserIcon,
    },
    watch: { userPhotoFilePath: function() { this._setUserPhotoFileUrl() } },
    data() {
      return {
        files: [],
        userPhotoFileUrl: '',
      }
    },
    computed: {
      _tagRemovedText: function() { return this.taggedText.replace(/(<([^>]+)>)/gi, "") },

      displayDate() {
        var d = this.postedDate.toDate()
        var st = d.getFullYear() + "/" + 
                 (d.getMonth() + 1) + "/" +
                 d.getDate() + " " +
                 d.getHours() + ":" +
                 d.getMinutes()
        return st
      },
      cardCss: function() {
        return {
          card: this.isColoredWhenHovered,
          cardNoHover: !this.isColoredWhenHovered,
          selected: this.isSelected,
        }
      },
      statusCss: function() {
        return {
          status: this.isStatusColoredWhenHovered,
          statusNoHover: !this.isStatusColoredWhenHovered
        }
      }
    },
    methods: {
      ...mapActions('storage', {
        storageGetDownloadUrl: 'getDownloadUrl',
        storageGetUsericonDownloadUrl: 'getUsericonDownloadUrl',
      }),

      async _setUserPhotoFileUrl() {
        this.userPhotoFileUrl =  await this.storageGetUsericonDownloadUrl(this.userId)
      },

      bellMouseOver() {
        //this.bellSelected = !this.bellSetted
      },
      bellMouseLeave() {
        //this.bellSelected = this.bellSetted
      },
    }
  }
</script>
<style lang="scss" scoped>
  .card {
    @include block( 
      $list-type: 'a',
      $padding: 0 
    );

    &:hover {
      cursor: pointer;
    }
  }

  .cardNoHover {
    @include block( 
      $list-type: 'a',
      $hover-background-color: $base-bg-color,
      $padding: 0 
    );
  }

  .selected {
    background-color: '#DDD';
  }

  .card-question-upper {
     @include block( 
       $border: 0,
       $hover-background-color: transparent,
       $background-color: transparent,
       $list-type: 'v'
     );

     &.header {
       display: flex;
       justify-content: space-between;
       &>h3 {
          pointer-events: none;
          text-decoration: none;
          margin: 0;
       }

       &>div:hover {
         cursor: pointer;
       }
     }

     &.resolved {
        background-color: #303030;
        color: #707070;
     }

     &.body {
        @include block(
          $border: 0,
          $padding: 0,
          $color: $base-input-color,
          $hover-background-color: transparent,
          $background-color: transparent
        );

        overflow:hidden;
        margin: 40px 0 20px 0;

        >p {
          width: 100%;
          margin: 0;
          font-size: 0.9rem;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
     }
     &.body-full {
       box-sizing: border-box; 
       overflow-wrap: anywhere;
     }
  }
  .card-question-lower {
     @include block( 
        $border: 0,
        $background-color: transparent,
        $hover-background-color: transparent,
        $padding: 0,
        $list-type: 'h' 
     );

     display: flex;
     justify-content: space-between;

     &>div {
       @include block( 
          $padding: 5px,
          $border: 0,
          $hover-background-color: transparent,
          $background-color: transparent,
       );
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
     }

     &>div:not(last-child) {
       border: 1px solid $base-border-color;
     }

     &.date {
       font-size: 0.8rem;
       margin-left: 5px;
       color: $base-input-color;
       flex-wrap: wrap;
     }

     .status {
       @include input-block(
         $color: $base-input-accent-color,
         $border-radius: 0 0 3px 0,
         $padding: 10px
       );
       font-size: 0.9rem;

       &:hover {
         cursor: pointer;
       }
     }

     .statusNoHover {
       @include input-block(
         $color: $base-input-accent-color,
         $hover-background-color: $base-input-bg-color,
         $hover-color: $base-input-accent-color,
         $border-radius: 0 0 3px 0,
         $padding: 10px
       );
       font-size: 0.9rem;
     }

  }
  
</style>
