import firebase from 'firebase/app'
import 'firebase/auth'

var provider = new firebase.auth.GoogleAuthProvider();

export default {
  namespaced: true,

  state: {
    uid: undefined,
    displayName: '',
    photoUrl: '',
    email: ''
  },


  getters: {
    isSignedIn: state => { return state.displayName != ''}
  },


  mutations: {
    setUid(state, payload) {
      state.uid = payload
    },

    setDisplayName(state, payload) {
      state.displayName = payload
    },

    setPhotoUrl(state, payload) {
      state.photoUrl = payload
    },

    setEmail(state, payload) {
      state.email = payload
    },

  },


  actions: {
    getCurrentUser() {
      return new Promise( resolve => {
        var unsubscribe = firebase.auth().onAuthStateChanged( 
          user => { resolve(user); unsubscribe(); })
      })
    },

    async initState(ctx) {
      var user = await ctx.dispatch('getCurrentUser')
      if ( user ) {
        ctx.commit('setUid', user.uid)
        ctx.commit('setDisplayName', user.displayName)
        ctx.commit('setPhotoUrl', user.photoURL)
        ctx.commit('setEmail', user.email)
      }
      else {
        ctx.commit('setUid', '')
        ctx.commit('setDisplayName', '')
        ctx.commit('setPhotoUrl', '')
        ctx.commit('setEmail', '')
      }
      console.log('auth user init.')
    },

    async signIn(ctx) {
      await firebase.auth().signInWithPopup(provider)
        .then( () => { })
        .catch((error) => {
          console.log("errorCode: " + error.code)
          console.log("errorMessage: " + error.message)
          console.log("errorEmail: " + error.email)
          console.log("errorCredential: " + error.credential)
        });
      await ctx.dispatch('initState')
      return await ctx.dispatch('getCurrentUser')
    },

    async signOut(ctx) {
      await firebase.auth().signOut()
        .then( () => { alert("ログアウトしました。") })
        .catch( error => {
          console.log(`ログアウト時にエラーが発生しました (${error})`);
        });
      await ctx.dispatch('initState')
      return await ctx.dispatch('getCurrentUser')
    },

  },


  module: {
  }
}
