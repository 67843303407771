<template>
  <div>
    <div 
      v-for="q in getQuestions()" 
      :key="q.id"
      style="margin-bottom: 40px;">

      <qa-q-card
        :questionId="q.id"
        :title="q.data().title"
        :postedDate="q.data().createdAt"
        :taggedText="q.data().htmlText"
        :userId="q.data().postUserId"
        :userName="q.data().postUserDisplayName"
        :userRouterTo="{name: 'user',  params: { uid: q.data().postUserId } }"
        :point="q.data().point"
        :isResolved="q.data().status === 'resolved'"
        :isSelected="false"
        :showFullText="false"
        @click="() => {}"
        :buttonText="getButtonText(q)"
        :buttonDisabled="getButtonDisabled(q)"
        @buttonClick="buttonClicked(q)" 
        :filePath="q.data().filePath"
        :isColoredWhenHovered="false"
        :isStatusColoredWhenHovered="q.data().status != 'resolved' && q.data().postUserId === uid"
        @bellClick="setNotification"
        :bellSetted="_notified(uid + '_' + q.id)"
      />
    </div>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import QaQuestionCard from '@/components/molecules/QaCard-Question'

  export default {
    props: {
      isResolved: Boolean
    },
    components: {
      'qa-q-card': QaQuestionCard
    },
    computed: {
      ...mapState('auth-user', [
        'uid',
        'email'
      ]),
      ...mapState('store-question', [
        'questionsFilteredByUid',
      ]),
      ...mapState('store-notification', [
        'notifications'
      ]),
    },
    methods: {
      ...mapActions('store-notification', [
        'addNotification',
        'deleteNotification',
      ]),
      getButtonText: function(q) {
        if ( q.data().status === 'resolved' ) return '解決済み'
        if ( q.data().postUserId === this.uid ) return '解決済みにする'
        return '未解決'
      },
      getButtonDisabled: function(q) {
        if ( q.data().status != 'resolved' && q.data().postUserId === this.uid ) return false
        return true
      },
      buttonClicked: function(q) {
        if ( q.data().status != 'resolved' && q.data().postUserId === this.uid )
          this.$router.push({name: 'answer-evaluate', params: {questionId: q.id, uid: this.uid}})
      },
      getQuestions() {
        if (this.isResolved)
          return this.questionsFilteredByUid.filter(q => q.data().status === "resolved")
        else return this.questionsFilteredByUid.filter(q => q.data().status != "resolved")
      },
      _notified(notificationId) {
        if (this.notifications.includes(notificationId))
          console.log('notified')
        else
          console.log('not notified')
        return this.notifications.includes(notificationId)
      },
      
      async setNotification( questionId, title,  e ) {
        // Event停止
        e.stopPropagation()

        var n = {}
        n.uid = this.uid
        n.questionId = questionId
        n.title = title
        n.email = this.email
        
        if ( this._notified(this.uid + "_" + questionId) )
          await this.deleteNotification(n) 
        else
          await this.addNotification(n)
      },
    }
  }
</script>
