<template>
  <layout>
    <template v-slot:contents>
      <h3 style="display: block; margin: auto;">
        <slot name="message"></slot>
      </h3>
      <h6>※自動的に切り替わります</h6>
    </template>
  </layout>
</template>
<script>
  import Layout from '@/components/organisms/QaLayout'
  export default {
    props: {
      redirectTo: {
        type: String,
        default: '/'
      }
    },
    components: {
      'layout': Layout,
    },
    mounted() {
      setTimeout(this.transfer, 3000)
    },
    methods: {
      transfer() {
        this.$router.push(this.redirectTo)
      }
    }
  }
</script>
