<template>
  <ul>
    <qa-sidebar-li v-for="(i, index) in items" :key="index" :isSelected="i.name === selectedTag" :click="i.action">
      <div v-if="i.icon"><div v-html="i.icon"></div> </div>
      <a>  
        {{ i.key }}
      </a>
    </qa-sidebar-li>
  </ul>
</template>
<script>
import QaSidebarLi from '@/components/atoms/QaSidebarLi'

export default  {
  props: { 
    selectedTag: {
      type: String
    },
    items: {
      type: Array,
      required: true
    }
  },
  components: {
    'qa-sidebar-li': QaSidebarLi
  },
}
</script>

<style lang="scss" scoped>
  ul {
    li {
      cursor: pointer;
    
      div { 
        display: inline;
        margin-right: 5px;
      }
      a {
        @include anchor();
      }
    }
  }
</style>
