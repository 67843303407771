<template>
  <layout>
    <template v-slot:contents>
      <!-- header -->
      <h3>マイページ</h3>
      <hr>
      <!-- /header -->

      <!-- image -->
      <p>画像</p>
      <dl style="display: flex; flex-wrap: wrap;">
        <dt>画像 ：</dt>
        <dd>
          <img :src="photoUrl" alt="[user-icon]" style="height: 40px;">
        </dd>
      </dl>
      <div>
      <input 
        type="file" 
        style="display: block; margin: auto;"  
        @input="fileChanged" 
        onMouseOut="this.style.cursor='pointer';" 
        onMouseOver="this.style.cursor='pointer'"
      />
      </div>
      <div style="display: flex;justify-content:end;">
        <qa-button @click="updateImage">更新</qa-button>
      </div>
      <hr>
      <!-- image -->

      <!-- profile  -->
      <p>プロフィール</p>
      <dl style="display: flex; flex-wrap: wrap;">
        <dt>表示名 ：</dt>
        <dd>
          <qa-text-input type="text" id="displayName" :value="displayName" @input="displayNameInputed" />
        </dd>
        <dt>場所 ：</dt>
        <dd>
          <qa-text-input type="text" id="place" :value="place" @input="placeInputed" />
        </dd>
        <dt>URL ：</dt>
        <dd>
          <qa-text-input type="text" id="url" :value="url" @input="urlInputed" />
        </dd>
        <dt>所属 ：</dt>
        <dd>
          <qa-text-input type="text" id="belongTo" :value="belongTo" @input="belongToInputed" />
        </dd>
        <dt>一言紹介：</dt>
        <dd>
          <qa-text-input type="text" id="profile" :value="profile" @input="profileInputed"></qa-text-input>
        </dd>
    </dl>
    <div style="display: flex;justify-content:end;">
      <qa-button @click="updateProfile">更新</qa-button>
    </div>
    <div v-if="uid === $route.params.uid" style="margin-right: 20px;">
      <router-link :to="{ name: 'user' }"> 
        <i class="fa-solid fa-arrow-left"></i> 
        戻る
      </router-link>
    </div>
    <!-- profile  -->
    </template>
  </layout>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import Layout from '@/components/organisms/QaLayout'
  import QaButton from '@/components/atoms/QaButton'
  import QaTextInput from '@/components/atoms/QaTextInput'
  import Swal from 'sweetalert2'
  //import QaTagTextInput from '@/components/atoms/QaTextInput-Tag'
  export default {
    components: {
      'layout': Layout,
      'qa-button': QaButton,
      'qa-text-input': QaTextInput,
      //'qa-tag-text-input': QaTagTextInput,
    },
    async mounted() {
      await this.initStateByUid(this.$route.params.uid)
      var user = await this.getUser(this.$route.params.uid)
      var photoUrl = await this.getUsericonDownloadUrl(this.$route.params.uid);
      this.displayName = user.data().displayName 
      this.photoUrl = photoUrl
      this.place = user.data().place
      this.url = user.data().url
      this.belongTo = user.data().belongTo
      this.profile = user.data().profile
      this.tags = user.data().tags
    },
    data() {
      return {
        displayName: '',
        photoUrl: '',
        place: '',
        url: '',
        belongTo: '',
        profile: '',
        tags: [],
        selectedFile: null,
      }
    },
    computed: {
      ...mapState('auth-user', [
        'uid',
      ]),
      ...mapState('store-user', {
        storeUserDisplayName: 'displayName',
      })
    },
    methods: {
      ...mapActions('store-question', [
        'initStateByUid'
      ]),
      ...mapActions('store-user', [
        'getUser',
        'updateUserPhoto',
        'updateUser'
      ]),
      ...mapActions('storage', [
        'getUsericonDownloadUrl',
        'uploadUsericonFile',
      ]),
      displayNameInputed(e) { this.displayName = e.target.value },
      placeInputed(e) { this.place = e.target.value },
      urlInputed(e) { this.url = e.target.value },
      belongToInputed(e) { this.belongTo = e.target.value },
      profileInputed(e) { this.profile = e.target.value },
      tagChanged(e) { this.tags = e.target.value }, 
      qIconClicked() { this.$router.push( {name: 'question-form'}) },
      fileChanged(e) { this.selectedFile = e.target.files[0]; /*e.preventDefault();*/ },
      async updateProfile() {
        var props = {}
        props.displayName = this.displayName
        props.place = this.place
        props.url = this.url
        props.belongTo = this.belongTo
        props.profile = this.profile
        props.tags = this.tags
        props.uid = this.uid
        await this.updateUser(props);
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: '更新しました！',
          showConfirmButton: false,
          timer: 1000
        })
        //this.$router.push({ name: 'updated'})
      },
      async updateImage() {
        // validation
        var IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png|svg|svg\+xml)$/i;
        console.log(this.selectedFile.type)
        if ( !IMAGE_MIME_REGEX.test(this.selectedFile.type)) {
           Swal.fire({
             icon: 'error',
             title: '画像種類エラー',
             text: '画像タイプで登録ください。',
           })
           return
        }

        // upload image
        var props = {}
        props.uid = this.uid
        props.file = this.selectedFile
        await this.uploadUsericonFile(props);
        this.photoUrl = await this.getUsericonDownloadUrl(this.$route.params.uid);
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: '更新しました！',
          showConfirmButton: false,
          timer: 1000
        })
      },
    }
  }
</script>
<style lang="scss" scoped>
  dl {
    margin: auto;
    width: 50%;
    dt {
      text-align: right;
      line-height: 40px;
      width: 30%;
      margin-bottom: 30px;
    }
    dd > p {
      margin: auto 0 auto 0;
    }
    dd {
      display: flex;
      margin-left: 0;
      width: 70%;
      margin-bottom: 30px;
    }
  }
</style>
