<template>
  <div v-if="selectedQuestion">
    <qa-q-card 
        :questionId="selectedQuestion.id"
        :title="selectedQuestion.data().title"
        :postedDate="selectedQuestion.data().createdAt"
        :taggedText="selectedQuestion.data().htmlText"
        :userId="selectedQuestion.data().postUserId"
        :userName="selectedQuestion.data().postUserDisplayName"
        :userRouterTo="{name: 'user',  params: { uid: selectedQuestion.data().postUserId } }"
        :point="selectedQuestion.data().point"
        :isResolved="selectedQuestion.data().status === 'resolved'"
        :isSelected="false"
        :showFullText="true"
        @click="() => {return }"
        :buttonText="getButtonText(selectedQuestion)"
        :buttonDisabled="getButtonDisabled(selectedQuestion)"
        @buttonClick="buttonClicked" 
        :filePath="selectedQuestion.data().filePath"
        style="margin-bottom: 20px;"
        :isColoredWhenHovered="false"
        :isStatusColoredWhenHovered="selectedQuestion.data().status != 'resolved' && selectedQuestion.data().postUserId === uid"
        @bellClick="setNotification"
        :bellSetted="_notified(uid + '_' + selectedQuestion.id)"
      />
    <hr v-if="answers.length != 0" style="margin-bottom: 20px;" />
    <div v-for="a in answers" :key="a.id" style="margin-bottom: 40px;">
      <qa-a-card
        :answerId="a.id"
        :postedDate="a.data().createdAt"
        :taggedText="a.data().htmlText"
        :userId="a.data().postUserId"
        :userName="a.data().postUserDisplayName"
        :userRouterTo="{name: 'user',  params: { uid: a.data().postUserId } }"
        :isResolved="selectedQuestion.data().status === 'resolved'"
        :isSelected="false"
        :filePath="a.data().filePath"
        :isColoredWhenHovered="false"
      />
    </div>
      <!-- routerTo [TODO]   -->
  </div>
</template>
<script>
  import { mapGetters, mapState, mapActions } from 'vuex'
  import QaQuestionCard from '@/components/molecules/QaCard-Question'
  import QaAnswerCard from '@/components/molecules/QaCard-Answer'

  export default {
    components: {
      'qa-q-card': QaQuestionCard,
      'qa-a-card': QaAnswerCard
    },
    computed: {
      ...mapState('auth-user', [
        'uid',
        'email'
      ]),
      ...mapGetters('store-question', [
        'selectedQuestion',
      ]),
      ...mapState('store-answer', [
        'answers'
      ]),
      ...mapState('store-notification', [
        'notifications'
      ]),
    },
    methods: {
      ...mapActions('store-question', [
        'getQuestion',
      ]),
      ...mapActions('store-notification', [
        'addNotification',
        'deleteNotification',
      ]),
      getButtonText: function(q) {
        if ( q.data().status === 'resolved' ) return '解決済み'
        if ( q.data().postUserId === this.uid ) return '解決済みにする'
        return '未解決'
      },
      getButtonDisabled: function(q) {
        if ( q.data().status != 'resolved' && q.data().postUserId === this.uid ) return false
        return true
      },
      async buttonClicked(questionId) {
        var q = await this.getQuestion(questionId)
        console.log(q)
        if ( q.data().status != 'resolved' && q.data().postUserId === this.uid )
          this.$router.push({name: 'answer-evaluate', params: {questionId: questionId}})
      },
      _notified(notificationId) {
        if (this.notifications.includes(notificationId))
          console.log('notified')
        else
          console.log('not notified')
        return this.notifications.includes(notificationId)
      },
      
      async setNotification( questionId, title,  e ) {
        // Event停止
        e.stopPropagation()

        var n = {}
        n.uid = this.uid
        n.questionId = questionId
        n.title = title
        n.email = this.email
        
        if ( this._notified(this.uid + "_" + questionId) )
          await this.deleteNotification(n) 
        else
          await this.addNotification(n)
      },
    }
  }
</script>
