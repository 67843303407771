<template>
  <input 
    :id="id" 
    :type="type" 
    :value="value" 
    @input="$emit('input', $event)"
    @blur="$emit('blur', $event.target.value)"
    @keydown="$emit('keydown', $event)" />
</template>
<script>
  export default {
    props: {
      id: {
        type: String,
        required: true
      },
      type: {
        type:String,
        required: true
      },
      value: { },
    }
  }
</script>
<style lang="scss" scoped>
  input {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 1px solid $app-border-color;
    border-radius: 3px;
    font-size: 1em;
    color: $app-color;
    background-color: $app-bg-color;
  }
</style>
