<template>
  <layout redirectTo="/">
    <template v-slot:message>
      投稿しました。
    </template>
  </layout>
</template>
<script>
  import Layout from '@/components/organisms/QaLayout-Redirect'
  export default {
    components: {
      'layout': Layout,
    },
  }
</script>
