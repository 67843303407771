<template>
  <div>
    <i class="fa-solid fa-magnifying-glass"></i>
    <input type="text" :value="value" @input="$emit('input', $event)" placeholder="Search" />
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: String,
        default: ''
      }
    }
  }
</script>
<style lang="scss" scoped>
  div {
    >input {
      @include input-block(
        $color: $base-input-color-dark,
        $hover-color: $base-input-color-dark,
        $hover-background-color: $base-input-bg-color
      );
      position: absolute;
      padding-left: 35px;
      z-index: 1;
      width: 100%;
    }

    >i {
      color: $base-input-color-dark;
      position: absolute;
      padding: 17px 10px;
      z-index: 2;
    }
  }

</style>
