<template>
   <img 
     :src="photoUrl" 
     alt="[user-icon]" 
     @click="click" 
     :style="[ height === 0 ? '' : { height: height + 'px'  }]"> 
</template>
<script>
  export default {
    props: {
      photoUrl: {
        type: String,
        default: ''
      },
      click: {
        type: Function,
        default: () => {} 
      },
      height: {
        type: Number,
        default: 0
      }
    }, 
  }
</script>
<style lang="scss" scoped>
    @include icon(
      $height: 30px,
      $margin: 0 0 0 0 
    );
</style>
