<template>
  <select id="id" :value="value" @change="$emit('change', $event)">
    <option v-for="(i, index) in items" :key="index" :value="i.value" :disabled="i.disabled">
      {{ i.key }}
    </option>
  </select>
</template>
<script>
  export default {
    props: {
      id: {
        type: String,
        required: true
      },
      value: {
        type: String,
        default: ''
      },
      items: {
        type: Array,
        required: true,
        default: () => []
      }
    }
  }
</script>
<style lang="scss" scoped>
  select {
    box-sizing: border-box;
    width: 100%;
    padding: 10px;
    border: 1px solid $app-bg-sub-color;
    border-radius: 3px;
    font-size: 1em;
    color: $app-color;
    background-color: $app-bg-color;
  }
</style>
