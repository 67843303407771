<template>
  <div class="q-form">
    <p style="text-align: right;font-size: 12px;"><span style="color: red;">*</span>必須入力</p>

    <!-- category -->
    <label for="q-category">メインカテゴリ<span style="color: red;">*</span></label>
    <qa-select-input id="q-category" :value="selectedCategory" :items="tagList" @change="categoryChanged" />
    <!-- /category -->

    <!-- title -->
    <label for="q-title">タイトル<span style="color: red;">*</span></label>
    <qa-text-input type="text" id="q-title" :value="title" @input="titleInputed"></qa-text-input>
    <!-- title -->

    <!-- text -->
    <label for="q-text">本文<span style="color: red;">*</span></label>
    <qa-md-editor id="q-text" 
      :value="text" 
      @input="textInputed" 
      @paste="pasted"
      placeholder="# 質問してみよう！" />
    <small>※画像はクリップボードから貼り付けできます。</small>
    <!-- /text -->

    <!-- imgs -->
    <div style="display: flex;">
      <div v-for="f of files" :key="f.path">
        <qa-img 
          :href="f.href" 
          :dataLightbox="f.dataLightbox"
          :showButton="true"
          @buttonClick="deleteFile(f.path)">
        </qa-img>
      </div>
    </div>
    <!-- imgs -->

    <!-- tag -->
    <label for="q-tag">タグ<span style="color: red;">*</span></label>
    <qa-tag-text-input id="q-tag" :value="qtag" @change="qtagChanged"></qa-tag-text-input>
    <!-- tag -->

    <!-- point -->
    <label for="q-point">支払いポイント数<span style="color: red;">*</span></label>
    <qa-text-input type="number" id="q-point" :value="point" @input="pointInputed" @keydown.69.prevent @wheel.prevent ></qa-text-input>
    <!-- point -->

    <!-- limit -->
    <label for="q-limit">回答期日<span style="color: red;">*</span></label>
    <qa-text-input type="datetime-local" id="q-limit" :value="limit" @input="limitInputed"></qa-text-input>
    <!-- limit -->

    <div v-if="false">
      <!-- urgent -->
      <label for="q-isUrgent"> <input type="checkbox" id="q-isUrgent" v-model="isUrgent">至急！ </label>
      <!-- /urgent -->

      <!-- private -->
      <label for="q-isPrivate"> <input type="checkbox" id="q-isPrivate" v-model="isPrivate">プライベート </label>
      <!-- private -->
    </div>
    <qa-button class="post-button" @click="registerQuestion">投稿</qa-button>
  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import QaSelectInput from '@/components/atoms/QaSelectInput'
  import QaTextInput from '@/components/atoms/QaTextInput'
  import QaTextInputTag from '@/components/atoms/QaTextInput-Tag'
  import QaButton from '@/components/atoms/QaButton'
  import QaMDEditor from '@/components/molecules/QaEditor-MD.vue'
  import QaImgThumbnail from '@/components/atoms/QaImgThumbnail.vue'
  import Swal from 'sweetalert2'

  export default {
    components: {
      'qa-md-editor': QaMDEditor,
      'qa-select-input': QaSelectInput,
      'qa-text-input': QaTextInput,
      'qa-tag-text-input': QaTextInputTag,
      'qa-button': QaButton,
      'qa-img': QaImgThumbnail
    },
    async mounted() {
      await this.storeTagInitState()
      var initLimit = new Date();
      this.limit = initLimit.getFullYear()
        + '-' + ('0' + (initLimit.getMonth() + 1)).slice(-2)
        + '-' + ('0' +  initLimit.getDate()).slice(-2)
        + 'T00:00'
        //+ 'T' + ('0' + (initLimit.getHours() + 3)).slice(-2)
        //+ ':' + ('0' +  initLimit.getMinutes()).slice(-2)
    },
    data (){
      return {
        selectedCategory: '',
        title: '',
        text: '',
        htmlText: '',
        qtag: [],
        point: 50,
        limit: '', 
        isUrgent: false,
        isPrivate: false,
        files: []
      }
    },
    computed: {
      ...mapState('auth-user',[
        'uid',
      ]),
      ...mapState('store-user', [
        'displayName',
        'photoUrl'
      ]),
      ...mapState('store-tag', [
        'tags'
      ]),
      tagList() {
        var list = []
        list.unshift( { key: '-- カテゴリを選択してください --', value: '', disabled: true })
        for ( let i = 0; i <  this.tags.length; i++) {
          list.push( {
            key: this.tags[i].data().displayName,
            value: this.tags[i].data().name,
            disabled: false
          })
        }
        return list
      }
    },
    methods: {
      ...mapActions('store-tag', {
        storeTagInitState: 'initState',
      }),
      ...mapActions('store-question', {
        storeRegisterQuestion: 'registerQuestion'
      }),
      ...mapActions('storage', {
        storageUploadFile: 'uploadQuestionFile',
        storageDeleteFile: 'deleteFile',
        storageGetDownloadUrl: 'getDownloadUrl',
      }),
      categoryChanged(e) { this.selectedCategory = e.target.value },
      titleInputed(e) { this.title = e.target.value },
      pointInputed(e) { this.point = e.target.value  },
      limitInputed(e) { this.limit = e.target.value },
      qtagChanged(e) { this.qtag = e.target.value }, 
      textInputed(e, htmlValue) { this.text = e.target.value; this.htmlText = htmlValue },
      async deleteFile(path) {
        this.files = this.files.filter( i => i.path != path )
        await this.storageDeleteFile(path)
      },
      async pasted(e) {
        var IMAGE_MIME_REGEX = /^image\/(p?jpeg|gif|png)$/i;
        var items = e.clipboardData.items;
        for (var i = 0; i < items.length; i++) {
          if (IMAGE_MIME_REGEX.test(items[i].type)) {
            var tempf = items[i].getAsFile()

            // upload
            console.log(items[i].getAsFile())
            var path = await this.storageUploadFile(tempf)
            var url = await this.storageGetDownloadUrl(path)

            // file reader 
            var reader = new FileReader()
            reader.onload = () => {
              var f = { 
                path: path,
                href: url,
                dataLightbox: path,
              }
              this.files.push(f)
            };

            // read
            reader.readAsDataURL(tempf);
            return;
          }
        }
        e.PreventDefault()
      },
      async registerQuestion() {
        // validation
        if ( !Number.isInteger(Number.parseInt(this.point))) {
           Swal.fire({
             icon: 'error',
             title: '質問内容確認中エラー',
             text: 'ポイントは数値で指定しましょう。',
           })
           return
        }
        if ( this.selectedCategory === '' || 
             this.title.trim() === '' ||
             this.htmlText.trim() === '' ||
             this.qtag.length === 0 ||
             this.limit.trim() === '') {
           Swal.fire({
             icon: 'error',
             title: '質問内容確認中エラー',
             text: '必須項目を入力しましょう。（カテゴリ、タイトル、本文、タグ、ポイント、期限）',
           })
           return
        }

        var q = {}
        var filePath = []
        for ( var f of this.files ){ 
          filePath.push(f.path) 
        }
        if ( !this.qtag.length ) { this.qtag = '[{"value":"' + this.selectedCategory + '"}]'}
        else { 
          var temp = JSON.parse(this.qtag)
          temp.push({value: this.selectedCategory })
          this.qtag = JSON.stringify(temp)
        }
        q.filePath = filePath
        q.uid = this.uid
        q.displayName = this.displayName
        q.title = this.title
        q.text = this.text
        q.htmlText = this.htmlText
        q.tags = this.qtag
        q.point = this.point
        q.isUrgent = this.isUrgent
        q.isPrivate = this.isPrivate
        q.limit = this.limit

        await this.storeRegisterQuestion(q)
        Swal.fire({
          position: 'top',
          icon: 'success',
          title: '投稿しました！',
          showConfirmButton: false,
          timer: 1000
        })
        this.text = ''
        this.files = []
        this.$router.push({ name: 'questions', query: this.$route.query})
      }
    }
  }
</script>
<style lang="scss" scoped>
  .q-form {
    @include block(
      $hover-background-color: transparent
    );
    max-height: 1500px;
    transition: max-height 0.3s ease;
    box-sizing: border-box;
    margin-bottom: 40px;

    &>label {
      margin-top: 15px;
      display: block;
    }

    &>div input{
      margin: 0 10px 0 0;
    }

    &>button {
      width: 100%;
    }

    .post-button {
      margin-top: 20px;
    }

  }
</style>
