<template>
  <div>
    <a :href="href" :data-lightbox="dataLightbox">
      <img :src="href" alt="[posted-image]">
    </a>
    <button v-if="showButton" @click="buttonClick">×</button>
  </div>
</template>
<script>
  export default {
    props: [
      'href',
      'dataLightbox',
      'showButton',
    ],
    methods: {
      buttonClick() {
        this.$emit('buttonClick');
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  }
</script>
<style lang="scss" scoped>
  img {
    height: 50px;
    border-radius: 5px;
    margin-right: 10px;
  }
</style>
