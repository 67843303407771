<template>
  <li :class="{ selected : isSelected }" @click="click" > 
    <slot ></slot> 
  </li>
</template>
<script>
  export default {
    props: {
      isSelected: {
        type: Boolean
      },
      click: {
        type: Function,
        default: () => {}
      },
    }
  }
</script>

<style lang="scss" scoped>
  li { 
    @include block($list-type: 'v'); 
  }
</style>
