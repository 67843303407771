<template>
  <ul>
    <li v-for="(i, index) in items" :key="index">
      <qa-button @click="i.action"> {{ i.key }} </qa-button>
    </li>
  </ul>
</template>
<script>
  import QaButton from '@/components/atoms/QaButton'

  export default {
    props: { 
      items: {
        type: Array,
        required: true
      }
    },
    components: {
      'qa-button' : QaButton,
    },
  }
</script>
<style lang="scss" scoped>
  ul {
     @include input-block(
       $background-color: $base-input-bg-color-middle-dark,
       $hover-color: $base-input-color,
       $hover-background-color: $base-input-bg-color-middle-dark,
       $padding: 5px 
     );
      
     margin: 0;
     display: flex;
     justify-content: space-between;

     li {
        display: inline-block;
        
        button {
          @include input-block(
          $padding: 8px 20px,
          $color: $base-input-color-dark,
          $background-color: $base-input-sub-color
          );
          margin: 0 4px;
        }
     }
  }

</style>

