<template>
  <header>
    <div>
      <!-- Left Contents -->
      <qa-logo-icon />
      <!-- /Left Contents -->
      <!-- Right Contents -->
      <template v-if="uid">
        <div style="position: relative;">

          <div style="display: flex; flex-direction: column;align-items: center;">
            <qa-user-icon name="" :photoUrl="photoUrl" :height="50" :click="click" class="header-uicon" />
            <small v-if="credit != null"> 所有pt: {{ credit }}pt</small>
          </div>
          <!-- drop down list -->
          <template v-if="isOpened">
            <ul class="dropdown">
                <li v-for="(i, index) in menus" :key="index" @click="i.action"> {{ i.name }} </li>
            </ul>
          </template>
        <!-- /drop down list -->
        </div>
        <div v-if="isOpened" class="dropdown-bg" @click="isOpened = !isOpened" ></div>
      </template>
      <template v-else>
        <qa-button @click='signIn' class="signIn">
          <i class="fa-regular fa-user"></i>
          Log in
      </qa-button>
      </template>
      <!--/Right Contents -->
    </div>
  </header>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import QaLogoIcon from '@/components/atoms/QaIcon-Logo'
import QaUserIcon from '@/components/atoms/QaIcon-User'
import QaButton from '@/components/atoms/QaButton'
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import 'tippy.js/themes/light.css';
import 'tippy.js/animations/scale.css';


export default {
  components: {
    'qa-logo-icon': QaLogoIcon,
    'qa-user-icon': QaUserIcon,
    'qa-button': QaButton,
  },
  computed: {
    ...mapState('auth-user', {
      uid: 'uid',
      authDisplayName: 'displayName',
    }),
    ...mapState('store-user', {
      displayName: 'displayName',
      photoUrl: 'photoUrl',
      credit: 'credit',
    }),
  },
  data() {
    return {
      isOpened: false,
      menus: [
        { name: 'my page' , action: this.routerPush },
        { name: 'sign out' , action: this.signOut }
      ]
    }
  },
  async created() {
    await this.authInitState()
    await this.storeUserInitState(this.uid)

    tippy('.signIn', {
      content: "Let's Register!!",
      placement: 'left',
      arrow: true,
      animation: 'scale',
      showOnCreate: true,
      theme: "light",
      delay: [100, null],
    });
  },
  methods: {
    ...mapActions('auth-user', {
      authInitState: 'initState',
      authSignIn: 'signIn',
      authSignOut:'signOut',
    }),
    ...mapActions('store-user', {
      storeUserInitState: 'initState',
      storeUserRegister: 'registerUser'
    }),
    async signIn() {
      await this.authSignIn()
      await this.storeUserRegister({uid: this.uid, displayName: this.authDisplayName})
      //this.$router.push( { name: 'user', params: { uid: this.uid }} )
    },
    async signOut() {
      await this.authSignOut()
      await this.storeUserInitState(this.uid)
      if ( this.$route.name != 'base' ) this.$router.push( { name: 'base' } )
    },
    click () {
      this.isOpened = !this.isOpened
    },
    routerPush () {
      this.$router.push( { name: 'user', params: { uid: this.uid }} )
    },
  }
}
</script>
<style lang="scss" scoped>
  header { 
     @include header();

     .header-uicon {
       &:hover {
         cursor: pointer;
       }
     }

     >div {
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 1000px;
        margin: 0 30px 0 30px;

        >div {
           @include block(
              $background-color: $base-accent-color,
              $border: 1px solid $base-accent-color
              );
           >ul {
              list-style: none;
              position: absolute;
              z-index: 3;
              padding: 0;
              right: 20px;
              
              >li {
                width: 75px;
                @include block();
                @include anchor();

                &:hover {
                  cursor: pointer;
                }
              }    
           }
        }
        .dropdown-bg {
          width: 100vw;
          height: 100vh;
          position: absolute;
          opacity: 0;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 2;
        }
        &>button {
          color: $base-input-sub-color;
          background-color: $base-accent-color;
          
          &:hover {
            color: $base-accent-color;
            background-color: $base-input-bg-color;
          }
        }
     }
  }
</style>
