import Vue from 'vue'
import Vuex from 'vuex'
import AuthUser from '@/store/qa-auth-user.js'
import Storage from '@/store/qa-storage.js'
import StoreUser from '@/store/qa-store-user.js'
import StoreTag from '@/store/qa-store-tag.js'
import StoreQuestion from '@/store/qa-store-question.js'
import StoreAnswer from '@/store/qa-store-answer.js'
import StoreNotification from '@/store/qa-store-notification.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    'auth-user': AuthUser,
    'storage': Storage,
    'store-user': StoreUser,
    'store-tag': StoreTag,
    'store-question': StoreQuestion,
    'store-answer': StoreAnswer,
    'store-notification': StoreNotification,
  }
})
