import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";

Vue.config.productionTip = false

const firebaseConfig = {
  apiKey: "AIzaSyBk7-0-d-p3nJNL0zMmbcwcpVxFqxOYL8o",
  authDomain: "it-qa-e2483.firebaseapp.com",
  projectId: "it-qa-e2483",
  storageBucket: "it-qa-e2483.appspot.com",
  messagingSenderId: "703897682726",
  appId: "1:703897682726:web:86014ac7b0e7a6250637e7",
  measurementId: "G-8PRFWXMYWL",
};
firebase.initializeApp(firebaseConfig)
//firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
