<template>
  <router-link :to="routerTo">
    <img src="@/assets/image/logo.png" alt="logo" style="height: 90px;">
  </router-link>
</template>
<script>
export default {
  props: {
    routerTo:{
      type: String,
      default: '/'
    }
  }
}
</script>
