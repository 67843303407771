<template>
  <layout>
    <template v-slot:contents>
      <!-- header -->
      <h3>マイページ</h3>
      <hr>
      <!-- /header -->

      <!-- profile -->
      <div style="display: flex;justify-content: space-between;">
        <p>プロフィール</p>
        <div v-if="uid === $route.params.uid">
          <router-link :to="{ name: 'user-profile-edit' }">編集</router-link>
          <i class="fas fa-external-link-alt"></i>
        </div>
      </div>
      <dl style="display: flex; flex-wrap: wrap;">
        <dt>画像 ：</dt>
        <dd>
          <img :src="photoUrl" alt="[user-icon]" style="height: 40px;">
        </dd>
        <dt>表示名 ：</dt>
        <dd>
          <p> {{ displayName }} </p>
        </dd>
        <dt>場所 ：</dt>
        <dd>
          <p>{{ place }}</p>
        </dd>
        <dt>URL ：</dt>
        <dd>
          <p>{{ url }}</p>
        </dd>
        <dt>所属 ：</dt>
        <dd>
          <p>{{ belongTo }}</p>
        </dd>
        <dt>一言紹介 ：</dt>
        <dd>
          <p>{{ profile }}</p>
        </dd>
      </dl>
      <hr>
      <!-- /profile -->

      <!-- point -->
      <div v-if="uid === $route.params.uid" >
        <p>所有ポイント</p>
          <div >
            <p> {{ credit }} point</p>
          </div>
        <hr>
      </div>
      <div v-if="uid === $route.params.uid" >
        <p>購入ポイント</p>
          <div style="display: flex;justify-content: space-betwwen;">
            <qa-text-input 
              type="number" 
              id="buyPoint"
              :value="buyPoint" 
              @input="buyPointInputed"
              style="width: 100px;margin-bottom: 18px;margin-right: 10px;"
              @keydown.69.prevent @wheel.prevent ></qa-text-input>
            <p>point</p>
          </div>
          <div id="paypal-button-container"></div>
        <hr>
      </div>
      <!-- /point -->

      <!-- my questions -->
      <p>自分の質問</p>
        <h3>未解決の質問</h3>
        <qa-myq-cards :isResolved="false" />
        <hr>
        <h3>解決済みの質問</h3>
        <qa-myq-cards :isResolved="true" />
      <!-- /my questions -->
    </template>
  </layout>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import Layout from '@/components/organisms/QaLayout'
  import QaMyQuestionCards from '@/components/organisms/QaCards-MyQuestion'
  import { loadScript } from "@paypal/paypal-js";
  import QaTextInput from '@/components/atoms/QaTextInput'
  import Swal from 'sweetalert2'

  export default {
    components: {
      'layout': Layout,
      'qa-myq-cards': QaMyQuestionCards,
      'qa-text-input': QaTextInput,
    },
    async mounted() {
      await this.routeChanged(); 
      // テストID
      this.paypal = await loadScript({ 
        "client-id": "Ac4TUiujjYnzGb2soLxd4Rg14N_lcyOf2XwU_ID4GkWB8s9dnuC6phA7Pr9YAgn1jMgQcht8tgRIiyLd",
        "currency": "JPY" 
      });
      // 本番ID
      // this.paypal = await loadScript({ 
      //   "client-id": "Afr2eXVeOLtYTanScfvR39UhZSbUjzNL-6MnNUZ9SqzZB7-y9y9xInmz3OPKntTt-bPPaBC-tXv3bKCt",
      //   "currency": "JPY" 
      // });
      await this.initPayPalButton();
    },
    data() {
      return {
        displayName: '',
        photoUrl: '',
        place: '',
        url: '',
        belongTo: '',
        profile: '',
        tags: [],
        credit: 0,
        paypal: null,
        buyPoint: 10,
      }
    },
    watch: {
      async $route() { await this.routeChanged(); }
    },
    computed: {
      ...mapState('auth-user', [
        'uid',
      ]),
      ...mapState('store-question', [
        'questionsFilteredByUid'
      ]),
    },
    methods: {
      ...mapActions('store-question', [
        'initStateByUid'
      ]),
      ...mapActions('store-user', [
        'getUser',
        'calcPoint',
      ]),
      ...mapActions('storage', [
        'getUsericonDownloadUrl',
      ]),
      qIconClicked() { this.$router.push( {name: 'question-form' }) },
      buyPointInputed(e) { this.buyPoint = e.target.value },
      async routeChanged() {
        await this.initStateByUid(this.$route.params.uid)
        var user = await this.getUser(this.$route.params.uid)
        var photoUrl = await this.getUsericonDownloadUrl(this.$route.params.uid);
        this.displayName = user.data().displayName 
        this.photoUrl = photoUrl
        this.place = user.data().place
        this.url = user.data().url
        this.belongTo = user.data().belongTo
        this.profile = user.data().profile
        this.tags = user.data().tags
        this.credit = user.data().credit
      },
      async initPayPalButton() {

        var uid = this.uid
        var calc = this.calcPoint

        function getAmount() {
            var getSelectedValue = document.querySelector( '#buyPoint');  
            var amount = Number.parseInt(getSelectedValue.value, 10)
            return amount
        }

        await this.paypal.Buttons({
          style: {
            shape: 'rect',
            color: 'gold',
            layout: 'vertical',
            label: 'paypal',
            tagline: false,
          },
          createOrder: function (data, actions) {
            // validation
            if ( !Number.isInteger(Number.parseInt(getAmount()))) {
               Swal.fire({
                 icon: 'error',
                 title: '購入ポイント確認中エラー',
                 text: 'ポイントは数値で指定しましょう。',
               })
               return
            }
            if ( Number.parseInt(getAmount()) < 10) {
               Swal.fire({
                 icon: 'error',
                 title: '購入ポイント確認中エラー',
                 text: '購入は10Point以上からお願いします。',
               })
               return
            }

            return actions.order.create({
              purchase_units: [{ 
                "amount": { "currency_code": "JPY", "value": getAmount() } 
              }]
            });
          },
          onApprove: function (data, actions) {
            return actions.order.capture().then(async function (orderData) {

              // Full available details
              console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

              // Show a success message within this page, e.g.
              const element = document.getElementById('paypal-button-container');
              element.innerHTML = '';
              element.innerHTML = '<h3>Thank you for your payment!</h3>';

              // Or go to another URL:  actions.redirect('thank_you.html');
              var prop = {}
              prop.uid = uid
              prop.addPoint = getAmount
              await calc(prop)

            });
          },
          onError: function (err) {
              console.log(err);
          }
        }).render('#paypal-button-container');
      },
    }
  }
</script>
<style lang="scss" scoped>
  a {
    margin-left: 30px;
    &:hover {
      color: #FF7787;
    }
  }

  dl {
    margin: auto;
    width: 50%;
    dt {
      text-align: right;
      line-height: 40px;
      width: 30%;
    }
    dd > p {
      margin: auto 0 auto 0;
    }
    dd {
      display: flex;
      margin: 0;
      width: 70%;
    }
  }
</style>

