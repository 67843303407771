import firebase from 'firebase/app'
import 'firebase/storage' 
import Compressor from 'compressorjs'

export default {
  namespaced: true,

  state: {
  },


  mutations: {
  },


  actions: {

    _compressImage(ctx, file) {
      return new Promise( (resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          convertSize: 5000,
          success: (result) => { resolve(result) },
          error: (err) => { reject(err) }
        })
      })
    },


    async getDownloadUrl(ctx, path) {
      var storage = firebase.storage();
      var ref = storage.ref(path);
      var url = await ref.getDownloadURL();
      console.log(url)
      return url
    },


    async getUsericonDownloadUrl(ctx, uid) {
      var storage = firebase.storage();
      var ref = storage.ref('user-images/' + uid);
      var url = ''
      await ref.getMetadata().then(() => {
        url = ref.getDownloadURL();
      }).catch(() => {
        ref = storage.ref('user-images/default-icon.svg')
        url = ref.getDownloadURL();
      });
      return url
    },


    async setDefaultUsericon(ctx, uid) {
      var storage = firebase.storage();
      var ref = storage.ref('user-images/' + uid);
      var url = await ref.getDownloadURL();
      console.log(url)
      return url
    },


    async uploadUsericonFile(ctx, { file, uid }) {
      // compress
      var conved = await ctx.dispatch('_compressImage', file)
      console.log('conved')

      // upload
      var storage = firebase.storage();
      var ref = storage.ref('user-images/' + uid);
      ref.put(conved).then( () => { console.log('uploaded.') });
      return ref.fullPath
    },


    async uploadQuestionFile(ctx, file) {
      // random filename
      const S = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      const N = 16;
      const fileName = Array.from(crypto.getRandomValues(new Uint32Array(N)))
       .map((n) => S[n % S.length])
       .join("");
      const date = new Date()

      // compress
      var conved = await ctx.dispatch('_compressImage', file)
      console.log('conved')
 
        // upload
      var storageRef = firebase.storage().ref();
      var ref = storageRef.child('questions/' +
        date.getFullYear() + 
		("00" + (date.getMonth()+1)).slice(-2) +
		("00" + date.getDate()).slice(-2) +
		'/' + 
		("00" + date.getHours()).slice(-2) + 
		("00" + date.getMinutes()).slice(-2) +
		("00" + date.getSeconds()).slice(-2) +
		'-' +fileName)
      await ref.put(conved).then( () => { console.log('uploaded.') });

      return ref.fullPath
    },
    

    async deleteFile( ctx, path) {
      var storageRef = firebase.storage().ref();
      var desertRef = storageRef.child(path);

      // Delete the file
      desertRef.delete().then(function() {
        // File deleted successfully
		console.log('deleted.')
      }).catch(function() {
        // Uh-oh, an error occurred!
      });

    }
  },

}
