<template>
  <form @submit.prevent="search">
    <qa-large-text :value='value' @input="$emit('input', $event.target.value)" /> 
  </form>
</template>
<script>
  import QaTextInputLarge from '@/components/atoms/QaTextInput-Large'
  export default {
    components: {
      'qa-large-text': QaTextInputLarge,
    },
    props: {
      value: {
        type: String,
        default: ''
      },
      search: {
        type: Function,
        required: true
      }
    },
  }
</script>
