import Vue from 'vue'
import VueRouter from 'vue-router'
import QaBaseView from '@/components/templates/QaBaseView.vue'
import QaRedirectViewPosted from '@/components/templates/QaRedirectView-Posted.vue'
import QaRedirectViewResolved from '@/components/templates/QaRedirectView-Resolved.vue'
import QaRedirectViewProfileUpdated from '@/components/templates/QaRedirectView-ProfileUpdated.vue'
import QaUserPageView from '@/components/templates/QaUserPageView.vue'
import QaUserPageViewEdit from '@/components/templates/QaUserPageView-Edit.vue'
import QaEvaluateAnswerView from '@/components/templates/QaEvaluateAnswerView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'base',
    component: QaBaseView 
  },
  {
    path: '/questions',
    name: 'questions',
    component: QaBaseView 
  },
  {
    path: '/question/edit',
    name: 'question-edit',
    component: QaBaseView
  },
  {
    path: '/question/posted',
    name: 'question-posted',
    component: QaBaseView
  },
  {
    path: '/question/:questionId/answers',
    name: 'answers',
    component: QaBaseView
  },
  {
    path: '/question/:questionId/answer/posted',
    name: 'answer-posted',
    component: QaBaseView
  },
  {
    path: '/user/:uid',
    name: 'user',
    component: QaUserPageView
  },
  {
    path: '/user/:uid/edit',
    name: 'user-profile-edit',
    component: QaUserPageViewEdit
  },
  {
    path: '/user/:uid/questions/:questionId/eval',
    name: 'answer-evaluate',
    component: QaEvaluateAnswerView
  },
  {
    path: '/posted',
    name: 'posted',
    component: QaRedirectViewPosted 
  },
  {
    path: '/resolved',
    name: 'resolved',
    component: QaRedirectViewResolved
  },
  {
    path: '/updated',
    name: 'updated',
    component: QaRedirectViewProfileUpdated
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
