import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    notifications: [],
  },


  mutations: {
    setNotification(state, payload) {
      state.notifications = payload
    },
    addNotification(state, payload) {
      state.notifications.push(payload)
    },
  },


  actions: {
    async initState(ctx, uid){
      ctx.commit('setNotification', [])

      await firebase.firestore().collection("Notifications")
          .where("userId", "==", uid)
          .get().then( querySnapshot => {
            querySnapshot.forEach( doc => { 
              ctx.commit('addNotification', doc.id) 
            });
      });
      console.log('notifications init')
    },


    async addNotification(ctx, n ) {
      var docId = n.uid + "_" + n.questionId
      // check file existence.
      var doc = await firebase.firestore().collection("Notifications").doc(docId).get()
      console.log(doc)

      if (doc.exists) {
          console.log("Document already exists.", docId);
          return
      }
      
      await firebase.firestore().collection("Notifications").doc(docId)
        .set({
          userId: n.uid,
          questionId: n.questionId,
          title: n.title,
          email: n.email
        })
        .then( () => {
          console.log("Document written with ID: ", docId);
          ctx.commit('addNotification', docId)
          console.log('add document.')
        })
        .catch((error) => {
          console.error("Error adding document: ", error);
        });
    },


    async deleteNotification(ctx, n) {
      var docId = n.uid + "_" + n.questionId
      await firebase.firestore().collection("Notifications") .doc(docId).delete()
      console.log(ctx.state.notifications)
      var tempNotification = ctx.state.notifications.filter( function(n) {
        return n != docId
      })
      ctx.commit('setNotification', tempNotification)
      console.log(ctx.state.notifications)
    },
  }
}
