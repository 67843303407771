<template>
  <input :id="id" :value="value" class="tagify" @change="$emit('change', $event)" >
</template>
<script>
  import Tagify from "@yaireo/tagify/dist/tagify.min.js";

  export default {
    props: {
      id: {
        type: String,
        required: true
      },
      mode: String,
      settings: Object,
      value: [String, Array],
      onChange: Function
    },
    watch: {
      value(newVal /*, oldVal*/) {
        this.tagify.loadOriginalValues(newVal)
      },
    },
    mounted() {
      this.tagify = new Tagify(this.$el, this.settings)
    }
  }
</script>
