import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    answers: [],
    answersListener: null,
    question: null
  },


  mutations: {
    setAnswers(state, payload) {
      state.answers = payload
    },
    addAnswer(state, payload) {
      state.answers.push(payload)
    },
    setQuestion(state, payload) {
      state.question = payload
    },
    setAnswersListener(state, payload) {
      if ( state.answersListener ) state.answersListener();
      state.answersListener = payload
    }
  },


  actions: {
    async initState(ctx){
      if ( ctx.state.question === null ) return

      ctx.commit('setAnswers', [])
      var listener = await firebase.firestore().collection("Answers")
        .where("questionId", "==", ctx.state.question.id)
        .orderBy("createdAt", "asc")
        .onSnapshot( querySnapshot => {
          ctx.commit('setAnswers', [])
          querySnapshot.forEach((doc) => {
            ctx.commit('addAnswer', doc)
          });
        });
      await ctx.commit('setAnswersListener', listener)
      setTimeout( () => { 
        if ( ctx.state.answersListener != null ) ctx.state.answersListener(); 
      }, 600000) 
      console.log('answers init')
    },

    async setQuestion(ctx, q) {
      await ctx.commit('setQuestion', q)
      if ( q ) await ctx.dispatch('initState')
    },

    async registerAnswer(ctx, a) {
      firebase.firestore().collection("Answers").add({
        filePath: a.filePath,
        questionId: a.questionId,
        postUserId: a.uid,
        postUserPhotoFilePath: 'user-images/' + a.uid,
        postUserDisplayName: a.displayName,
        text: a.text,
        htmlText: a.htmlText,
        goodSum: 0,
        badSum: 0,
        recievedPoint: 0,
        recievedPointPercent: 0,
        answerHistories: [],
        parentAnswerId: '',
        paid: false,
        status: "created",
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
      var listener = await firebase.firestore().collection("Answers")
        .where("questionId", "==", ctx.state.question.id)
        .orderBy("createdAt", "asc")
        .onSnapshot( querySnapshot => {
          ctx.commit('setAnswers', [])
          querySnapshot.forEach((doc) => {
            ctx.commit('addAnswer', doc)
          });
        });
      await ctx.commit('setAnswersListener', listener)
      setTimeout( () => { 
        if ( ctx.state.answersListener != null ) ctx.state.answersListener(); 
      }, 600000) 
      console.log('answers init')
    },


    async resolveAnswer(ctx, props) {
      await firebase.firestore().collection('Answers').doc(props.aid).get().then( (doc) => {
        doc.ref.update( {
          recievedPoint: firebase.firestore.FieldValue.increment(props.addPoint),
          wholePaidPoint: props.totalPoint,
          paid: 'true',
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
      })
    },
  }
}
