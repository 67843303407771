<template>
  <div class="layout container">
    <!-- Header -->
    <qa-header />
    <!-- /Header -->
  
    <!-- main -->
    <main v-if="uid === undefined">
    </main>
    <main v-else-if="uid">
      <div class="side-contents"> 
        <qa-sidebar class="qa-sidebar" :items="tagRouteFuncList" :selectedTag="selectedTag" /> 
      </div>
      <div class="main-contents"> <slot name="main-contents" ></slot> </div>
    </main>
    <main v-else>
      <div style="display: flex; justify-content: center; align-items: center;width: 100%; flex-direction: column;">
        <h1>アクセス頂きありがとうございます！</h1>
        <h2>画面右上よりユーザー登録をお願いいたします。</h2>
      </div>
    </main>
    <!-- /main -->

  </div>
</template>
<script>
  import { mapState, mapActions } from 'vuex'
  import QaHeader from '@/components/organisms/QaHeader'
  import QaSidebar from '@/components/organisms/QaSidebar'

  export default {
    components: {
      'qa-header': QaHeader,
      'qa-sidebar': QaSidebar,
    },
    computed: {
      ...mapState('auth-user', {
        uid: 'uid',
      }),
      ...mapState('store-tag', [
        'tags'
      ]),
      tagRouteFuncList() {
        var list = []
        list.unshift( { 
          key: 'すべて',
          name: 'すべて',
          icon: '<i class="fa-solid fa-chart-line"></i>',
          sort: -1,
          action: () => { 
            var queries = Object.assign( {}, this.$route.query)
            delete queries['tag']
            this.$router.push({ name: 'questions', query: queries })
          }
        })

        for ( let i = 0; i <  this.tags.length; i++) {
          list.push( {
            key: this.tags[i].data().displayName,
            name: this.tags[i].data().name,
            icon: this.tags[i].data().icon,
            sort: this.tags[i].data().sort,
            action: () => {
              var queries = Object.assign( {}, this.$route.query)
              queries['tag'] = this.tags[i].data().name,
              this.$router.push({ name: 'questions', query: queries })
            }
          })
        }

        return list
      },

      selectedTag() {
        if ( this.$route.query.tag  != undefined ) return this.$route.query.tag
        else return 'すべて' 
      },
    },
    async mounted() {
      console.log(this.uid)
      await this.storeTagInitState(false)
    },
    methods: {
      ...mapActions('store-tag', {
        storeTagInitState: 'initState'
      }),
      getUserRouterTo() {
        return  {name: 'user',  params: {uid: this.uid} }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .layout
  {
     min-height: 100vh;
     min-width: 1000px;

    &>main {
      display: flex;
      margin: 85px auto 100px auto;

      .side-contents {
        min-width: 170px;
        max-width: 170px;
        margin-left: 30px;
        margin-right: 50px;
        flex-grow: 1;
        flex-basis: 100px;
        position: relative;

        .qa-sidebar {
          margin: 0;
          padding: 0;
          position: sticky;
          list-style: none;
          top: 0;
        }
      }

      .main-contents {
        min-width: 780px;
        margin-right: 30px;
        flex-grow: 4;
        flex-basis: 500px;
      }
    }
  }
</style>

