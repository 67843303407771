<template>
  <qa-layout>
    <template v-slot:main-contents>

      <!-- control section -->
      <section class="control-section">

        <!-- text search -->
        <div style="width: 35%; position: relative;">
        <qa-text-search 
          @input="keywordChanged" 
          :search="keywordSearch"
          :value="keyword" />
        </div>
        <!-- /text search -->
        
        <!-- filter -->
        <div style="width: 56%; margin-left: auto;">
          <qa-button-line :items="buttonItems"></qa-button-line>
        </div>
        <!-- /filter -->
      </section>
      <!-- /control section -->

      <section class="qa-contents container">
        <!-- Left Contents -->
        <div :class="_contentsLeftCss" v-if="!aFormExpanded">
          <qa-button @click="qFormButtonClicked" style="margin-bottom: 15px;">
    
            {{ qFormButtonText }}
            <i v-if="!qFormVisible" class="fas fa-expand-alt"></i> 
            <i v-if="qFormVisible" class="fas fa-compress-alt"></i> 
          </qa-button>
          <qa-qpost-form v-if="qFormVisible" />
          <qa-q-cards />
        </div>
        <!-- /Left Contents -->
        <!-- Right Contents -->
        <div :class="_contentsRightCss" v-if="selectedQuestionId != null && !qFormVisible">
          <qa-button @click="aFormButtonClicked" style="margin-bottom: 15px;">
            {{ aFormButtonText }}
            <i v-if="!aFormExpanded" class="fas fa-expand-alt"></i> 
            <i v-if="aFormExpanded" class="fas fa-compress-alt"></i> 
          </qa-button>
          <qa-a-cards></qa-a-cards>
          <qa-apost-form />
        </div>
          <!-- /Right Contents -->
      </section>
      <!-- /main-contents -->
    </template>
  </qa-layout>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import QaLayout from '@/components/organisms/QaLayout-2Pane'
  import QaTextSearch from '@/components/molecules/QaTextSearch'
  import QaButtonLine from '@/components/molecules/QaButtonLine'
  import QaQuestionPostForm  from '@/components/organisms/QaForm-QuestionPost'
  import QaAnswerPostForm  from '@/components/organisms/QaForm-AnswerPost'
  import QaButton from '@/components/atoms/QaButton'
  import QaQuestionCards from '@/components/organisms/QaCards-Question'
  import QaAnswerCards from '@/components/organisms/QaCards-Answer'

  export default {
    components: {
      'qa-layout': QaLayout,
      'qa-text-search': QaTextSearch,
      'qa-button-line': QaButtonLine,
      'qa-button': QaButton,
      'qa-qpost-form': QaQuestionPostForm,
      'qa-apost-form': QaAnswerPostForm,
      'qa-q-cards': QaQuestionCards,
      'qa-a-cards': QaAnswerCards,
    },

    async mounted() {
      await this.authGetCurrentUser()
      await this.routeChanged()
      await this.storeNotificationInitState(this.uid)
    },
    data() {
      return {
        keyword: '',
        buttonItems: [
          { key: 'すべて', action: () => { this.filter('all', '') } },
          { key: '未解決', action: () => { this.filter('resolved', 'false') } },
          { key: '解決済', action: () => { this.filter('resolved', 'true') } },
          { key: '自分の質問', action: () => { this.filter('ismine', 'true') } }
        ],
        state: { 
          QUESTIONS: 0,
          QUESTION_ANSWERS: 1,
          QUESTION_FORM: 2,
          ANSWER_FORM: 3,
        },
        qFormVisible: false,
        aFormExpanded: false,
      }
    },

    computed: {
      qFormButtonText: function(){ return this.qFormVisible  ? '元に戻す' : '質問を投稿する' },
      aFormButtonText: function(){ return this.aFormExpanded ? '元に戻す' : '回答を投稿する' },
      _contentsLeftCss: function() {
        return {
          'qa-contents': true,
          half: this.selectedQuestionId != null && !this.qFormVisible,
          halfLeft: this.selectedQuestionId != null && !this.qFormVisible,
          noneLeft: this.selectedQuestionId != null && this.aFormExpanded,
        }
      },
      _contentsRightCss: function() {
        return {
          'qa-contents': true,
          half: this.selectedQuestionId != null && !this.aFormExpanded,
          fullRight: this.selectedQuestionId != null && this.aFormExpanded,
          rightBackground: true,
        }
      },
      ...mapState('auth-user', {
        uid: 'uid',
        authDisplayName: 'displayName',
      }),
      ...mapGetters('auth-user', {
        authIsSignedIn: 'isSignedIn'
      }),
      ...mapState('store-question', [
        'selectedQuestionId',
        'questions'
      ]),
    },

    watch: {
      async $route (to, from) { 
        await this.routeChanged(to, from); 
      }
    },

    methods: {
      ...mapActions('auth-user', {
        authSignIn: 'signIn',
        authGetCurrentUser: 'getCurrentUser'
      }),
      ...mapActions('store-user', {
        storeUserRegister: 'registerUser'
      }),
      ...mapActions('store-question', {
        storeQuestionInitState: 'initState',
        storeQuestionFilter: 'filter',
        storeSetSelectedQuestionId: 'setSelectedQuestionId',
        storeGetQuestion: 'getQuestion',
      }),
      ...mapActions('store-answer', {
        storeAnswerSetQuestion: 'setQuestion',
      }),
      ...mapActions('store-notification', {
        storeNotificationInitState: 'initState',
      }),

      /* set state */
      setState(state) {
        switch( state ){
          case this.state.QUESTIONS:
            this.aFormExpanded = false;
            this.qFormVisible = false;
            break;
          case this.state.QUESTION_ANSWERS:
            this.aFormExpanded = false;
            this.qFormVisible = false;
            break;
          case this.state.QUESTION_FORM:
            this.aFormExpanded = false;
            this.qFormVisible = true;
            break;
          case this.state.ANSWER_FORM:
            this.aFormExpanded = true;
            this.qFormVisible = false;
            break;
          default:
            break;
        }
      },
      /* /set state */

      /* text search */
      keywordChanged(e) { this.keyword = e },
      keywordSearch() {
        var queries = Object.assign( {}, this.$route.query)
        if (this.keyword.trim() != "" ) { 
          queries.word = this.keyword 
          this.$router.push( {name: 'questions', query: queries})
        } else {
          delete queries.word
          this.$router.push( {name: 'questions', query: queries})
        }
      },
      /* /text search */

      /* filter */
      filter(param, value) {
        var queries = Object.assign( {}, this.$route.query)
        // all
        if ( param === 'all') {
          delete queries['ismine']
          delete queries['resolved']
          this.$router.push( {name: 'questions', query: queries})
        }
        // filter 'param'
        else if (value.trim() != "" ) {
          queries[param] = value
          this.$router.push( {name: 'questions', query: queries})
        } 
        // unfilter 'param' (value is not selected)
        else {
          delete queries[param]
          this.$router.push( {name: 'questions', query: queries})
        }
      },
      /* /filter */

      /* question form */
      qFormButtonClicked() { 
        if ( this.$route.name === 'question-edit' ) {
          this.setState(this.state.QUESTIONS)
          this.$router.push({name: 'questions', query: this.$route.query})
        }
        else this.$router.push({name: 'question-edit', query: this.$route.query})
      },
      /* /question form */

      /* answer form */
      async aFormButtonClicked() {
        if ( this.authIsSignedIn ) { this.aFormExpanded = !(this.aFormExpanded)}
        else { 
          await this.authSignIn() 
          await this.storeUserRegister({uid: this.uid, displayName: this.authDisplayName})
          //this.$router.push( { name: 'user', params: { uid: this.uid }} )
        }
      },
      /* /answer form */

      /* route changed */
      routeChanged: async function() {
        var filters = {}

        // question-edit 
        if ( this.$route.name === 'question-edit' ) {
          // signed in user
          if ( this.authIsSignedIn ) { this.setState(this.state.QUESTION_FORM) } 
          // not signed in user
          else {
            this.setState(this.state.QUESTIONS)
            await this.authSignIn() 
            if ( this.authIsSignedIn ) {
              await this.storeUserRegister({uid: this.uid, displayName: this.authDisplayName})
              this.setState(this.state.QUESTION_FORM)
            }
          }
        }

        // answers
        else if ( this.$route.name === 'answers' ) {
          if ( this.questions.length === 0) {
            await this.storeQuestionInitState();
            await this.storeQuestionFilter(filters)
            await this.storeSetSelectedQuestionId(this.$route.params['questionId'])
            var temp_q = await this.storeGetQuestion(this.$route.params['questionId'])
            await this.storeAnswerSetQuestion(temp_q)
          }
          this.setState(this.state.QUESTION_ANSWERS)
        }

        // questions 
        else if ( this.$route.name === 'questions'  || this.$route.name === 'base') {
          await this.storeSetSelectedQuestionId(null)

          // question tag
          if ( this.$route.query.tag != undefined ) { filters.tag = this.$route.query.tag }
  
          // question resolved
          if (this.$route.query.resolved != undefined ) { filters.resolved = this.$route.query.resolved }
  
          // question post user id
          filters.postuserid = 
            (this.$route.query.ismine != undefined && this.$route.query.ismine) ? this.uid : null
  
          // question word
          filters.word = 
            this.$route.query.word != undefined ? this.$route.query.word : null
          this.keyword = filters.word === null ? '' : filters.word
  
          this.setState(this.state.QUESTIONS)
          await this.storeQuestionInitState();
          await this.storeQuestionFilter(filters)
        }

        // others
        else { this.setState( this.state.QUESTIONS ) }
      },
      /* /route changed */
    }
  }
</script>

<style lang="scss" scoped>

  .control-section {
    margin-bottom: 10px;
    max-width: 100%;
    position: relative;
    height: 60px;
  }

  .qa-contents {
    box-sizing: border-box;
    width: 100%;

    >div>button {
      width: 100%;
    }

    &.container {
      @include block(
        $border: 1.5px solid $base-border-color,
        $hover-background-color: $base-bg-color,
        $list-type: 'a'
      );
      display: flex;
      flex-direction: row;
    }

    &.half {
      width: 50%;
      transition: width 0.5s ease;
    }

    &.halfLeft {
      margin-right: 15px;
    }

    &.noneLeft {
      width: 0;
    }

   &.fullRight {
     width: 100%;
   }
   &.rightBackground {
     background-color: $base-accent-color;
   }
  }
</style>
