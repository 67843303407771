import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  namespaced: true,

  state: {
    questions: [],
    questionsFilteredByUid: [],
    selectedQuestionId: null
  },


  getters: {
    selectedQuestion: function(state) {
      if ( state.selectedQuestionId === null ) return null
      for (var q1 of state.questions) {
        if ( q1.id === state.selectedQuestionId ) return q1
      }
      for (var q2 of state.questionsFilteredByUid) {
        if ( q2.id === state.selectedQuestionId ) return q2
      }
      return null
    },
  },


  mutations: {
    setQuestions(state, payload) {
      state.questions = payload
    },
    addQuestion(state, payload) {
      state.questions.push(payload)
    },
    setQuestionsFilteredByUid(state, payload) {
      state.questionsFilteredByUid = payload
    },
    addQuestionFilteredByUid(state, payload) {
      state.questionsFilteredByUid.push(payload)
    },
    setSelectedQuestionId(state, payload) {
      state.selectedQuestionId = payload
    }
  },


  actions: {
    async filter(ctx, filters) {
      var temp = ctx.state.questions;
      // tag
      if ( filters.tag && filters.tag != null && filters.tag != '' ) {
        temp = temp.filter(q => q.data().tags.includes(filters.tag))
        console.log('[filter]tag: ' + filters.tag)
      }
      // resolved
      if ( filters.resolved && filters.resolved != null ) {
        if ( filters.resolved === 'true')  temp = temp.filter(q => q.data().status === "resolved")
        else temp = temp.filter(q => q.data().status != "resolved");
        console.log('[filter]resolved: ' + filters.resolved)
      }
      // is my question
      if ( filters.postuserid && filters.postuserid != null ) {
        temp = temp.filter(q => q.data().postUserId === filters.postuserid)
        console.log('[filter]postuserid: ' + filters.postuserid)
      }
      // word
      if ( filters.word && filters.word != null) {
        temp = temp.filter(q => (q.data().htmlText.includes(filters.word) || q.data().title.includes(filters.word) ) )
        console.log('[filter]word: ' + filters.word)
      }
      ctx.commit('setQuestions', temp)
    },


    async initState(ctx){
      ctx.commit('setQuestions', [])
      await firebase.firestore().collection("Questions").orderBy("createdAt", "desc").get().then((querySnapshot) => {
        querySnapshot.forEach( doc => { ctx.commit('addQuestion', doc) });
      });
      console.log('questions init')
    },


    async initStateByUid(ctx, uid){
      ctx.commit('setQuestionsFilteredByUid', [])
      await firebase.firestore().collection("Questions")
        .where("postUserId", "==", uid )
        .orderBy("createdAt", "desc").get().then( (querySnapshot) => {
          querySnapshot.forEach( doc => { ctx.commit('addQuestionFilteredByUid', doc) });
      });
      console.log('questions init by uid')
    },


    async setSelectedQuestionId(ctx, qid) {
      ctx.commit('setSelectedQuestionId', qid)
    },

    
    async getQuestion(ctx, qid) {
      for (var q1 of await ctx.state.questions) { if ( q1.id === qid ) return q1 }
      for (var q2 of await ctx.state.questionsFilteredByUid) { if ( q2.id === qid ) return q2 }
      return await firebase.firestore().collection("Questions").doc(qid).get()
    },

    
    async registerQuestion(ctx, q) {
      firebase.firestore().collection("Questions").add({
        filePath: q.filePath,
        postUserId: q.uid,
        postUserDisplayName: q.displayName,
        title: q.title,
        text: q.text,
        htmlText: q.htmlText,
        category: 'IT',
        tags: q.tags,
        status: "created",
        point: Number.parseInt(q.point),
        votedSum: 0,
        viewedSum: 0,
        urgent: q.isUrgent,
        published: true,
        private: q.isPrivate,
        limit: firebase.firestore.Timestamp.fromDate(new Date(q.limit)),
        parentQuestionId: '',
        updatedUser: q.uid,
        questionHistories: [],
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        console.log("Document written with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
    },


    async resolveQuestion(ctx, qid) {
      await firebase.firestore().collection('Questions').doc(qid).get().then( (doc) => {
        doc.ref.update( {
          status: 'resolved',
          updatedAt: firebase.firestore.Timestamp.now()
        })
      })
    }
  }
}
