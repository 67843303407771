<template>
  <div :class="cardCss">
    <!-- upper-content -->
    <div class="card-answer-upper" @click="$emit('click', answerId)">

      <!-- text -->
      <div class="card-answer-upper body-full">
        <div v-html="taggedText" class="markdown-body"></div>
      </div>
      <!-- /text -->

      <!-- thumbnails -->
      <div style="display: flex;">
        <div v-for="f of files" :key="f.path">
          <qa-img 
            :href="f.href" 
            :dataLightbox="f.dataLightbox"
            :showButton="false"
            @buttonClick="storageDeleteFile(f.path)">
          </qa-img>
        </div>
      </div>
      <!-- /thumbnails -->
    </div>
    <!-- /upper-content -->
    <!-- lower-content -->
    <div class="card-answer-lower" @click="$emit('click', answerId)">
      <!-- user icon,name -->
      <div style="flex-wrap: wrap;">
        <qa-user-icon :photoUrl="userPhotoFileUrl" :routerTo="userRouterTo" />
        <span style="margin: 0 10px; font-size: 0.9rem;"> {{ userName }} </span>
      </div>
      <!-- /user icon,name -->

      <!-- posted timestamp -->
      <div>
        <i class="fa-regular fa-clock card-answer-lower date"></i> 
        <p class="card-answer-lower date"> {{ displayDate }} </p>
      </div>
      <!-- /posted timestamp -->
    </div>
    <!-- /lower-content -->
  </div>
</template>
<script>
  import { mapActions } from 'vuex'
  import QaImgThumbnail from '@/components/atoms/QaImgThumbnail.vue'
  import QaUserIcon from '@/components/atoms/QaIcon-User'

  export default {
    props: {
      answerId: String,
      postedDate: Object,
      taggedText: String,
      userId: String,
      userName: String,
      userRouterTo: Object,
      isResolved: Boolean,
      isSelected: Boolean,
      filePath: Array,
      isColoredWhenHovered: Boolean,
    },
    components: {
      'qa-img': QaImgThumbnail,
      'qa-user-icon': QaUserIcon,
    },
    async mounted() {
      if ( this.filePath != undefined ) {
        for ( var i = 0; i < this.filePath.length; i ++) {
           var url = await this.storageGetDownloadUrl(this.filePath[i])
           var f = { 
             path: this.filePath[i],
             href: url,
             dataLightbox: this.filePath[i],
           }
           this.files.push(f)
        }
      }
      await this._setUserPhotoFileUrl()
    },
    watch: { userPhotoFilePath: function() { this._setUserPhotoFileUrl() } },
    data() {
      return {
        files: [],
        userPhotoFileUrl: '',
      }
    },
    computed: {
      cardCss: function() {
        return {
          card: this.isColoredWhenHovered,
          cardNoHover: !this.isColoredWhenHovered,
          resolved: this.isResolved && !this.isSelected,
          selected: this.isSelected
        }
      },
      displayDate() {
        if (this.postedDate === null) return
        var d = this.postedDate.toDate()
        var st = d.getFullYear() + "/" + 
                 (d.getMonth() + 1) + "/" +
                 d.getDate() + " " +
                 d.getHours() + ":" +
                 d.getMinutes()
        return st
      }
    },
    methods: {
      ...mapActions('storage', {
        storageGetDownloadUrl: 'getDownloadUrl',
        storageGetUsericonDownloadUrl: 'getUsericonDownloadUrl',
      }),
      async _setUserPhotoFileUrl() {
        this.userPhotoFileUrl =  await this.storageGetUsericonDownloadUrl(this.userId)
      },
    }
  }
</script>
<style lang="scss" scoped>
  .card {
    @include block( 
      $list-type: 'a',
      $padding: 0 
    );
  }

  .cardNoHover {
    @include block( 
      $list-type: 'a',
      $hover-background-color: $base-bg-color,
      $padding: 0 
    );
  }

  .card-answer-upper {
     @include block( 
       $border: 0,
       $hover-background-color: transparent,
       $background-color: transparent,
       $list-type: 'v'
     );


     &.body-full {
       box-sizing: border-box; 
       overflow-wrap: anywhere;
     }
  }

  .card-answer-lower {
     @include block( 
        $border: 0,
        $hover-background-color: transparent,
        $background-color: transparent,
        $padding: 0,
        $list-type: 'v' 
     );

     display: flex;
     justify-content: space-between;

     &>div {
       @include block( 
          $padding: 5px,
          $border: 0,
          $hover-background-color: transparent,
          $background-color: transparent,
       );
       width: 100%;
       display: flex;
       justify-content: center;
       align-items: center;
     }

     &>div:not(last-child) {
       border: 1px solid $base-border-color;
     }

     &.date {
       font-size: 0.8rem;
       margin-left: 5px;
       color: $base-input-color;
       flex-wrap: wrap;
     }
  }
</style>
