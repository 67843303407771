<template>
  <layout>
    <template v-slot:contents v-if="selectedQuestion != null">
      <h3>ポイント割り振り</h3>
      <qa-q-card  
        :questionId="selectedQuestion.id"
        :title="selectedQuestion.data().title"
        :postedDate="selectedQuestion.data().createdAt"
        :taggedText="selectedQuestion.data().htmlText"
        :userId="selectedQuestion.data().postUserId"
        :userName="selectedQuestion.data().postUserDisplayName"
        :userRouterTo="{name: 'user',  params: { uid: selectedQuestion.data().postUserId } }"
        :point="selectedQuestion.data().point"
        :isResolved="selectedQuestion.data().status === 'resolved'"
        :isSelected="false"
        :showFullText="true"
        @click="() => {return }"
        :buttonText="getButtonText(selectedQuestion)"
        :buttonDisabled="true"
        @buttonClick="buttonClicked(q)" 
        hoverColor='black'
      />
      <hr v-if="answers.length != 0"/>
      <div v-for="a in answers" :key="a.id" class="evaluate-answers">
        <qa-a-card class="card"
          :answerId="a.id"
          :postedDate="a.data().createdAt"
          :taggedText="a.data().htmlText"
          :userId="a.data().postUserId"
          :userName="a.data().postUserDisplayName"
          :userRouterTo="{name: 'user',  params: { uid: a.data().postUserId } }"
          :isResolved="selectedQuestion.data().status === 'resolved'"
          :isSelected="false"
        />
        <qa-text-input type="number" id="point" class="point"
          v-if="a.data().postUserId != uid"
          :value="point[a.id + '_' + a.data().postUserId]" 
          @input="pointInputed($event, a)"
          @keydown.69.prevent
          @wheel.prevent
          @blur="setTotalPoint">
        <!-- :disabled="a.data().postUserId === uid" -->
        </qa-text-input>
        <p v-if="a.data().postUserId != uid">pt</p>
      </div>
      <hr v-if="answers.length != 0"/>
      <div v-if="answers.length != 0" class="evaluate-point-sum">
        <h3>合計 消費予定ポイント</h3>
        <div>
          <h4 style="display: block;text-align: left;">
            {{ totalPoint }}pt / {{ selectedQuestion.data().point }}pt
          </h4>
        </div>
      </div>
      <div v-if="answers.length != 0">
        <qa-button 
          style="display: block; margin-left: auto;"
          @click="evaluateButtonClicked"
          >{{ buttonText }}</qa-button>
        <p :class="[ isError ? 'messageShown' : 'messageHidden' ]"> </p>
      </div>
      <div v-if="answers.length === 0">
        <p>まだ回答がついていないため完了処理を行うことができません。</p>
      </div>
    </template>
  </layout>
</template>
<script>
  import { mapState, mapGetters, mapActions } from 'vuex'
  import Layout from '@/components/organisms/QaLayout'
  import QaQuestionCard from '@/components/molecules/QaCard-Question'
  import QaAnswerCard from '@/components/molecules/QaCard-Answer'
  import QaTextInput from '@/components/atoms/QaTextInput'
  import QaButton from '@/components/atoms/QaButton'
  import Swal from 'sweetalert2'
  export default {
    components: {
      'layout': Layout,
      'qa-q-card': QaQuestionCard,
      'qa-a-card': QaAnswerCard,
      'qa-text-input': QaTextInput,
      'qa-button': QaButton
    },
    async mounted() {
      await this.authGetCurrentUser()
      await this.storeQuestionInitStateByUid(this.uid)
      await this.storeSetSelectedQuestionId(this.$route.params.questionId)
      await this.storeAnswerSetQuestion(this.selectedQuestion)


    },
    data() {
      return {
        point: {} ,
        totalPoint: 0,
        isError: false,
        buttonText: 'ポイントを支払い、解決済みにする',
        canResolve: false,
      }
    },
    computed: {
      ...mapState('auth-user', [
        'uid'
      ]),
      ...mapGetters('store-question', [
        'selectedQuestion'
      ]),
      ...mapState('store-answer', [
        'answers'
      ]),
    },
    watch: {
      answers: function(answers){
        if ( answers ) {
          for ( var a of answers) 
            this.$set(this.point, a.id + "_" + a.data().postUserId, "0")
        }
        if ( !answers || answers.length === 0 ) {
          this.buttonText = '質問を取り下げる'
          this.canResolve = false
        } else {
          this.buttonText = 'ポイントを支払い、解決済みにする'
          this.canResolve = true
        }
      }
    },
    methods: {
      ...mapActions('auth-user', {
        authGetCurrentUser: 'getCurrentUser'
      }),
      ...mapActions('store-question', {
        storeQuestionInitStateByUid: 'initStateByUid',
        storeSetSelectedQuestionId: 'setSelectedQuestionId',
        storeResolveQuestion: 'resolveQuestion'
      }),
      ...mapActions('store-answer', {
        storeAnswerSetQuestion: 'setQuestion',
        storeAnswerInitState: 'initState',
        storeResolveAnswer: 'resolveAnswer'
      }),
      ...mapActions('store-user', {
        storeUserCalcPoint: 'calcPoint'
      }),
      pointInputed(e, a) { this.point[a.id + "_" + a.data().postUserId] = e.target.value },
      qIconClicked() {
        this.$router.push( {name: 'question-form'})
      },
      getButtonText: function(q) {
        if ( q.data().status === 'resolved' ) return '解決済み'
        if ( q.data().postUserId === this.uid ) return '解決済みにする'
        return '未解決'
      },
      getButtonDisabled: function(q) {
        if ( q.data().status != 'resolved' && q.data().postUserId === this.uid ) return false
        return true
      },
      buttonClicked: function(q) {
        if ( q.data().status != 'resolved' && q.data().postUserId === this.uid )
          this.$router.push({name: 'answer-evaluate', params: {questionId: q.id}})
      },
      setTotalPoint() {
        if ( Object.keys(this.point).length === 0 ) { this.totalPoint = 0 }
        var tp = 0
        for (const value of Object.values(this.point)) { 
          if (isNaN(parseInt(value))) { tp = 0; break;}
          tp = tp + parseInt(value) 
        }
        this.totalPoint = tp
      },
      withdrawButtonClicked() {
        alert('test')
      },
      evaluateButtonClicked() {
        if ( this.canResolve ){
          this.resolveButtonClicked()
        } else {
          this.withdrawButtonClicked()
        }
      },
      async resolveButtonClicked() {
        // error check
        if ( this.totalPoint < this.selectedQuestion.data().point ) {
          Swal.fire({
            icon: 'error',
            title: 'ポイント処理中にエラーが発生しました。',
            text: this.selectedQuestion.data().point + 'ポイント以上で割り振ってください！',
          })
          
          return this.isError = true
        }

        var result = Swal.DismissReason.cancel
        if ( this.totalPoint > this.selectedQuestion.data().point ) {
          result = await Swal.fire({
            title: 'ポイント精算確認',
            text: "ポイントの数が当初の予定とあっていませんが、確定しますか？",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'はい',
            cancelButtonText: 'いいえ',
            reverseButtons: true
          })
        } else {
          result = await Swal.fire({
            title: 'ポイント精算確認',
            text: "処理を確定します、よろしいですか？",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'はい',
            cancelButtonText: 'いいえ',
            reverseButtons: true
          })
        }

        if ( result.dismiss === Swal.DismissReason.cancel ) {
            Swal.fire(
              'キャンセルしました',
              '回答者へのお支払いをしませんでした。',
              'warning'
            )
            return
        }

        // update question
        await this.storeResolveQuestion(this.selectedQuestion.id)
        console.log('update question resolved.')

        // update user point
        var props = { uid: this.uid, addPoint: -1 * this.totalPoint }
        await this.storeUserCalcPoint(props)
        console.log('update user point subtracted.')

        for (const [key, value] of Object.entries(this.point)) {
          var temp_aid = key.substr(0, key.indexOf('_'))
          var temp_auid = key.substr(key.indexOf('_') + 1)

          // update answer
          var props2 = { aid: temp_aid, addPoint: parseInt(value), totalPoint: this.totalPoint }
          await this.storeResolveAnswer(props2)
          console.log('update answer ' + temp_aid +  ' resolved.')

          // update user point
          var props3 = { uid: temp_auid, addPoint: parseInt(value)  }
          await this.storeUserCalcPoint(props3)
          console.log('update user ' + temp_auid +  ' resolved.' + parseInt(value) + 'pt')
        }

        Swal.fire(
          '精算しました！',
          '回答者へお支払いしました。',
          'success'
        )
        this.$router.push({ name: 'resolved' } )

      }
    },
  }
</script>
<style lang="scss" scoped>
  .evaluate-answers
  {
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    &>.card {
      width: 83%;
    }
    &>.point {
      width: 10%;

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .evaluate-point-sum {
    display: flex;
    justify-content: space-between;
  }

  .messageShown {
    color: red;
    opacity: 1;
  }
  .messageHidden {
    opacity: 0;
  }
</style>
