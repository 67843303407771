import firebase from 'firebase/app'
import 'firebase/firestore'

export default {
  namespaced: true,


  state: {
    displayName: null,
    photoUrl: null,
    place: null,
    url: null,
    belongTo: null,
    profile: null,
    credit: null,
  },
  

  mutations: {
    setDisplayName(state, payload) {
      state.displayName = payload
    },
    setPhotoUrl(state, payload) {
      state.photoUrl = payload
    },
    setPlace(state, payload) {
      state.place = payload
    },
    setUrl(state, payload) {
      state.url = payload
    },
    setBelongTo(state, payload) {
      state.belongTo = payload
    },
    setProfile(state, payload) {
      state.profile = payload
    },
    setCredit(state, payload) {
      state.credit = payload
    },
  },


  actions: {
    async initState(ctx, uid) {

      if ( uid ) {
        var user = await firebase.firestore().collection('Users').doc(uid).get();
        var photoUrl = await ctx.dispatch('storage/getUsericonDownloadUrl', uid, {root: true}) 
        if ( user.exists) {
          ctx.commit('setDisplayName', user.data().displayName) 
          ctx.commit('setPhotoUrl', photoUrl) 
          ctx.commit('setPlace', user.data().place) 
          ctx.commit('setUrl', user.data().url) 
          ctx.commit('setBelongTo', user.data().belongTo) 
          ctx.commit('setProfile', user.data().profile) 
          ctx.commit('setCredit', user.data().credit) 
        }
      }
      else {
        ctx.commit('setDisplayName', null) 
        ctx.commit('setPhotoUrl', null) 
        ctx.commit('setPlace', null)
        ctx.commit('setUrl', null)
        ctx.commit('setBelongTo', null)
        ctx.commit('setProfile', null)
        ctx.commit('setCredit', null)
        ctx.commit('setCredit', null)
      }
    },


    async registerUser(ctx, { uid, displayName }) {
      // user not authed.
      if ( !uid ) { return }
      // user authed
      var user = await firebase.firestore().collection('Users').doc(uid).get();
      if ( !user.exists ) {
        await firebase.firestore().collection("Users").doc(uid).set({
          uid: uid,
          displayName: "it-qa user",
          authDisplayName: displayName,
          place: "",
          url: "",
          belongTo: "",
          profile: "",
          tags: [],
          role: "user",
          otherAccounts: {},
          credit: 100,
          lastLogined: firebase.firestore.FieldValue.serverTimestamp(),
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          updated_at: firebase.firestore.FieldValue.serverTimestamp(),
        })
      }
      else {
        await firebase.firestore().collection("Users").doc(uid).update({
          lastLogined: firebase.firestore.FieldValue.serverTimestamp()
        })
      }
      await ctx.dispatch('initState', uid)
      return user
    },

    
    async getUser(ctx, uid) {
      return firebase.firestore().collection('Users').doc(uid).get();
    },


    //async updateUserPhoto(ctx, props) {
    //  console.log(props.photoPath)
    //  await firebase.firestore().collection('Users').doc(props.uid).get().then( (doc) => {
    //    doc.ref.update( {
    //      photoPath: props.photoPath,
    //      updatedAt: firebase.firestore.FieldValue.serverTimestamp()
    //    })
    //  })
    //  console.log(props)
    //  await ctx.dispatch('initState', props.uid)
    //},


    async updateUser(ctx, props) {
      await firebase.firestore().collection('Users').doc(props.uid).get().then( (doc) => {
        doc.ref.update( {
          displayName: props.displayName,
          place: props.place,
          url: props.url,
          belongTo: props.belongTo,
          profile: props.profile,
          tags: props.tags,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
      })
      console.log(props)
      await ctx.dispatch('initState', props.uid)
    },


    async calcPoint(ctx, props) {
      await firebase.firestore().collection('Users').doc(props.uid).get().then( (doc) => {
        doc.ref.update( {
          credit: firebase.firestore.FieldValue.increment(props.addPoint),
          updatedAt: firebase.firestore.FieldValue.serverTimestamp()
        })
      })
      console.log(props)
    }
  }
}
