<template>
  <div class="layout container">

    <!-- Header -->
    <qa-header />
    <!-- /Header -->
  
    <!-- main -->
    <main>
      <slot name="contents" ></slot>
    </main>
    <!-- /main -->

  </div>
</template>
<script>
  import QaHeader from '@/components/organisms/QaHeader'

  export default {
    components: {
      'qa-header': QaHeader,
    },
    data() {
      return {
        userRouterTo: {name: 'user',  params: {uid: this.uid}},
      }
    },
  }
</script>
<style lang="scss" scoped>
  .layout
  {
    &.container 
    {
      min-height: 100vh;
      position: relative;
      padding-bottom: 30px;
    }

    &>main {
      max-width: 1000px;
      margin: 0 auto 0 auto;
    }

  }
</style>

