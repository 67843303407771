<template>
  <section>
    <div class="cl-qeditor">
        
      <textarea 
        :placeholder="placeholder" 
        :value="value" 
        @input="$emit('input', $event, getHtmlValue($event.target.value))" 
        @paste="$emit('paste', $event)"
        />
      <div v-html="htmlValue" class="markdown-body"></div>
    </div>

   <!--
    <div class="cl-qeditor-menu">
      <div class="cl-qeditor-menu-container-left">
        <button type="button" @click="toggleBold" class="cl-menu-function" :class="{ active: isBold }">
          <i class="fas fa-bold fa-x"></i>
        </button>
        <button type="button" @click="toggleItalic" class="cl-menu-function" :class="{ active: isItalic }">
          <i class="fas fa-italic fa-x"></i>
        </button>
        <button type="button" @click="toggleStrikethrough" class="cl-menu-function" :class="{ active: isStrikethrough }">
          <i class="fas fa-strikethrough fa-x"></i>
        </button>
        <button type="button" @click="toggleUl" class="cl-menu-function" :class="{ active: isUl }">
          <i class="fas fa-list-ul fa-x"></i>
        </button>
        <button type="button" @click="toggleOl" class="cl-menu-function" :class="{ active: isOl }">
          <i class="fas fa-list-ol fa-x"></i>
        </button>
        <button type="button" @click="toggleCode" class="cl-menu-function" :class="{ active: isCode}">
          <i class="fas fa-code fa-x"></i>
        </button>
        <button type="button" @click="toggleQuoted" class="cl-menu-function" :class="{ active: isQuoted}">
          <i class="fas fa-quote-right fa-x"></i>
        </button>
      </div>
      <div class="cl-qeditor-menu-container-right">
        <button @click="selectEmoji" class="cl-menu-function cl-emoji" >
          <i class="fas fa-smile fa-x"></i>
        </button>
      </div>
    </div>
    -->
  </section>
</template>

<script>
//import { Picker } from 'emoji-mart-vue'
import tippy from 'tippy.js'
import marked from 'marked'
import _ from 'lodash'
import dompurify from 'dompurify'
import highlight from 'highlight.js'

export default {
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    value: {
      type: String
    }
  },

  async mounted() {
    marked.setOptions({
      renderer: new marked.Renderer(),
      highlight: function(code, lang) {
        const language = highlight.getLanguage(lang) ? lang : 'plaintext'
        return highlight.highlight(code, { language  }).value
      },
      smartypants: true,
      breaks: true
    });
    tippy('#cl-emoji', {
        content: 'My tooltip!',
    });
  },

  data() {
    return {
      editorInput: "",
      editorPosStart: 0 ,
      editorPosEnd: 0,
      isBold: false,
      isItalic: false,
      isStrikethrough: false,
      isUl: false,
      isOl: false,
      isQuoted: false,
      isCode: false,
    }
  },

  computed: {
    htmlValue: function() {
      return dompurify.sanitize(marked(this.value, { sanitize: true }))
    }
  },

  methods : {
    getHtmlValue(input) {
      return dompurify.sanitize(marked(input, { sanitize: true }))
    },







    // get text which command is inserted at the caret
    _getCommInsertedText(command, isOneside) {
      if ( isOneside ) {
        var lastIdx = this.editorInput.lastIndexOf('\n', this.editorPosStart) == -1 ? 0 : (this.editorInput.lastIndexOf('\n', this.editorPostStart) + 1)
        return this.editorInput.slice(0, lastIdx) + command + this.editorInput.slice(lastIdx);
      } else {
        return this.editorInput.slice(0, this.editorPosStart) + command + command + this.editorInput.slice(this.editorPosStart);
      }
    },
    // get entering command string(bold, italic, strike-through, etc )
    _isCommEnabled (commandReg) {
      var startIdx = this.editorInput.substring(0, this.editorPosStart).includes("\n") ? this.editorInput.lastIndexOf("\n", this.editorPosStart+1) : 0
      var before = this.editorInput.substring(startIdx, this.editorPosStart)
      return (before.match( new RegExp(commandReg, "g")) || []).length % 2
    },
    // get text which command is removed at the caret
    _getCommRemovedText(command, commandReg, isOneside) {
      if ( isOneside ) {
        return this.editorInput.slice(0, this.editorInput.lastIndexOf(command, this.editorPosStart)) + this.editorInput.slice(this.editorInput.lastIndexOf(command, this.editorPosStart) + command.length);
        //var leftStr = this.editorInput.slice(0, this.editorInputthis.editorPosStart);
        //var reverseLeftStr = leftStr.split("").reverse().join(""))
        //return this.editorInput.slice(0, this.editorInput.lastIndexOf(command, this.editorPosStart)) + this.editorInput.slice(this.editorInput.lastIndexOf(command, this.editorPosStart) + command.length);
      } else {
        var temp = this.editorInput
        // Remove right side
        if ( (temp.slice(this.editorPosStart).match( new RegExp(commandReg, "g")) || []).length % 2 ) {
          temp = temp.slice(0, temp.indexOf(command, this.editorPosStart)) + temp.slice(temp.indexOf(command, this.editorPosStart) + command.length)
         }
        // Remove left side
        temp =  temp.slice(0, temp.lastIndexOf(command, this.editorPosStart)) + temp.slice(temp.lastIndexOf(command, this.editorPosStart) + command.length);
        return temp
      }
    },
    // toggle "bold"
    toggleBold() {
      if ( this.isBold ) {
        this.editorInput = this._getCommRemovedText("**", /\*\*/)
        this.isBold = false
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart - 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      } else {
        this.isBold = true
        this.editorInput = this._getCommInsertedText("**")
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart + 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      }
    }, 
    // toggle Italic
    toggleItalic() {
      if ( this.isItalic ) {
        this.editorInput = this._getCommRemovedText("*", /\*/)
        this.isItalic = false
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart - 1
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      } else {
        this.isItalic = true
        this.editorInput = this._getCommInsertedText("*")
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart + 1
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })

      }
    },
    toggleStrikethrough() {
      if ( this.isStrikethrough ) {
        this.editorInput = this._getCommRemovedText("~~", /~~/)
        this.isStrikethrough = false
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart - 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      } else {
        this.isStrikethrough = true
        this.editorInput = this._getCommInsertedText("~~")
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart + 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      }
    },
    toggleUl() {
      if ( this.isUl ) {
        this.isUl = false
        this.editorInput = this._getCommRemovedText("- ", /-\s/, true)
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart - 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      } else {
        this.isUl = true
        this.editorInput = this._getCommInsertedText("- ", true)
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart + 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      }
    },
    toggleOl() {
      if ( this.isOl ) {
        this.isUl = false
        this.editorInput = this._getCommRemovedText("- ", /[0-9]\s/, true)
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart - 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      } else {
        this.isOl = true
        this.editorInput = this._getCommInsertedText("- ", true)
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart + 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      }
    },
    toggleQuoted() {
      if ( this.isQuoted ) {
        this.isQuoted = false
        this.editorInput = this._getCommRemovedText("> ", />\s/, true)
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart - 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      } else {
        this.isQuoted = true
        this.editorInput = this._getCommInsertedText("> ", true)
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart + 2
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      }
    },
    toggleCode() {
      if ( this.isCode ) {
        this.editorInput = this._getCommRemovedText("\n```\n", /\n```\n/)
        this.isCode = false
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart - 5
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      } else {
        this.isCode = true
        this.editorInput = this._getCommInsertedText("\n```\n")
        // focus & set caret
        this.$refs.qeditor.focus()
        this.$nextTick( function() {
          this.editorPosStart = this.editorPosStart + 5
          this.$refs.qeditor.selectionStart = this.editorPosStart
          this.$refs.qeditor.selectionEnd = this.editorPosStart
        })
      }
    },
    onClick: _.debounce(function(e) {
      this.editorInput = e.target.value;
      this.editorPosStart = e.target.selectionStart

      this.isBold = this._isCommEnabled( /\*\*/ )
      this.isItalic = this._isCommEnabled( /\*/ )
      this.isStrikethrough = this._isCommEnabled( /~~/ )
    }, 200),
    keyPressed: _.debounce(function(e) {
      this.editorInput = e.target.value;
      this.editorPosStart = e.target.selectionStart

      this.isBold = this._isCommEnabled( /\*\*/ )
      this.isItalic = this._isCommEnabled( /\*/ )
      this.isStrikethrough = this._isCommEnabled( /~~/ )
      this.isUl = this._isCommEnabled(/-\s/)
      //this.isOl: false,
      //this.isQuoted = this._isCommEnabled(//)
      //this.isCode: false,
    }, 200),
    update: _.debounce(function(e) {
      //console.log(e.target.value)
      this.editorInput = e.target.value;
      this.$emit('rawInput', this.editorInput)
      this.$emit('compiledInput', this.compiledMarkdown)
    }, 200),
    selectEmoji() {
      tippy('.cl-emoji', {
        content: '<Picker />',
        allowHTML: true,
      });
    }
  },

}
</script>

<style lang="scss" scoped>
  @import "../../assets/css/github-markdown-dark.css";
  .cl-qeditor {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .cl-qeditor > textarea, 
  .cl-qeditor > div {
    display: inline-block;
    width: 49%;
    height: 100%;
    vertical-align: top;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $base-input-bg-color-dark;
  }
  .cl-qeditor > div {
    @include block(
      $hover-background-color: transparent,
      $color: $base-input-color
    );
  }
  textarea {
    @include input-block(
      $hover-color: $base-input-color,
      $hover-background-color: $base-input-bg-color-dark
    );
    outline: none;
    resize: none;
    font-size: 14px;
  }
  .cl-qeditor-menu {
    border-left: 1px solid #AAA;
    border-bottom: 1px solid #AAA;
    border-right: 1px solid #AAA;
    border-radius: 0 0 3px 3px;
    display: flex;
    justify-content: space-between;
  }
  .cl-menu-function {
    background-color: white;

  }
  .cl-qeditor-menu button {
    outline: none;
    border: none;  /* 枠線を消す */
  }
  .cl-qeditor-menu button:hover {
    background-color: #707070;
    color: white
  }
  .active {
    background-color: #707070;
    color: white
  }
</style>
