import { render, staticRenderFns } from "./QaRedirectView-Posted.vue?vue&type=template&id=427cd69c&"
import script from "./QaRedirectView-Posted.vue?vue&type=script&lang=js&"
export * from "./QaRedirectView-Posted.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports